import MUIDataTable from "mui-datatables";
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { firebase } from "../../../firebase";
import { getWebOrders } from "../../../actions/getDataAction";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import _ from "lodash";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
// import {
//   cancelOrder,
//   cancelItem,
//   updateUserDetails,
// } from "../../../actions/orderActions";
import Popup from "../../../Components/Popup";
import "react-confirm-alert/src/react-confirm-alert.css";
import PopupRakuten from "../../../Components/PopupRakuten";
import Button from "@material-ui/core/Button";
import CustomToolbar from "./CustomToolbar";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import { deleteUserDetails } from "../../../actions/orderActions";
import DeleteIcon from "@material-ui/icons/Delete";
import ReactFileReader from "react-file-reader";
import moment from "moment";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import "./table.css";
import AWS, { S3 } from "aws-sdk";
import { Language } from "@material-ui/icons";
import Axios from "axios";
import { deleteDoc, insertDoc, updateDoc } from "../../../helpers/db";
import { languages } from "../../../static_json/languages";
import { csvToArray } from "../../../helpers/common_functions";

const admins = require("../../../static_json/admins.json");

const db = firebase.firestore();
// const admins = [
// 	"sharmistha@playshifu.com",
// 	"jay@playshifu.com",
// 	"ajinkya@playshifu.com",
// 	"anushree@playshifu.com",
// 	"shreya@playshifu.com",
// 	"abhishek@playshifu.com",
// ];
const devs = ["dev1@playshifu.com", "pms@playshifu.com"];
const ops = ["translation@playshifu.com"];
const others = ["contentdash@playshifu.com"];
const tactoGamesStartTab = 57;
const kits_mapping = [
	"new_keys", //1
	"generic",
	"count_common",
	"theo_common",
	"theo",
	"numero_common", //6
	"numerov2",
	"space",
	"dana",
	"mcc",
	"mccv2", //11
	"cm1",
	"cm2",
	"cm3",
	"link_common",
	"gears", //16
	"wordhunt",
	"memory",
	"frog",
	"physics_prototype",
	"pipes", //21
	"piano_common",
	"tory",
	"alphabeto_common",
	"cat",
	"fish", //26
	"dino",
	"puzzle",
	"oko",
	"oko2",
	"caveman", //31
	"crossword",
	"lettersDetective",
	"lm1",
	"lm2",
	"lm3", //36
	"catapult_common",
	"iggy",
	"tizi",
	"waterfall",
	"animals_common", //41
	"eli",
	"vaaria",
	"animals_lm",
	"detective_common",
	"pdet1", //46
	"pdet2",
	"pdet3",
	"farm_common",
	"happy_barn",
	"farm_lm_otis", //51
	"farm_lm2",
	"orboot_keys",
	"earth",
	"mars",
	"dinos", //56
	"new_keys_tacto",
	"Common",
	"Retro_Common",
	"Retro_Ludo",
	"Retro_TrollsAndLadders", //61
	"Retro_Checkers",
	"Retro_TicTacToe",
	"Laser_Common",
	"Laser_SanaAndPuppy",
	"Laser_Detective", //66
	"Laser_MazeProd",
	"Laser_LaserSlime",
	"Chess_Common",
	"Chess_ChessGame",
	// "chess_goes_bananas", //71
	// "candyland",
	// "solitaire",
	"Coding_Common", //71
	"Coding_CodingAnimals",
	"Coding_CodingGameTwoProto",
	"Coding_CodeOnTheRun",
	"Coding_CodingGameFour",
	"Electronics_Common", //76
	"Electronics_ElectronicsG",
	"Electronics_EGTwo", //78
	"Electronics_ElectronicsGameThree",
	"Electronics_ElectronicsLearnMode",
	"Dinos_Common",
	"Dinos_TactoDino", //82
	// "dino_adventures",
	// "dino_land",
	"Doctor_Common", //85
	"Doctor_DoctorOne",
	"Doctor_DoctorTwo",
	"Doctor_DoctorThree", //88
];

const plugo_csv = [
	"Key",
	"Content Type",
	"English",
	"Japanese",
	"French",
	"German",
	"Korean",
	"Chinese",
	"Polish",
	"Dutch",
	"Swedish",
	"Danish",
	"Norwegian",
	"ChineseTraditional",
	"Russian",
	"Czech",
	"Spanish",
	"Slovenian",
	"PortugueseBrazil",
	"Italian",
	"Game",
	"GT",
	"Character's Name & Voice",
];
const singleUpload_csv = ["Key", "Content Type", "Game", "GT"];
const tacto_csv = [
	"Key", //0
	"Content Type", //1
	"English", //2
	"Japanese", //3
	"Swedish", //4
	"Danish", //5
	"Russian", //6
	"Spanish", //7
	"Slovenian", //8
	"Korean", //9
	"Norwegian", //10
	"PortugueseBrazil", //11
	"Italian", //12
	"Estonian", //13
	"Latvian", //14
	"Lithuanian", //15
	"Game", //16
	"GT", //17
	"Character's Name & Voice", //18
];
const voiceData_csv = ["Key", "Content Type", "Character's Name & Voice", "Game"];

class DataTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			table_data: [],
			table_data_unfiltered: [],
			editPopupData: [],
			duplicates: [],
			woDuplicates: [],
			allData: [],
			open: false,
			openR: false,
			openD: false,
			openA: false,
			openF: false,
			editData: "",
			deleteData: [],
			deleteLookup: [],
			selectedTab: 0,
			selectedTabNew: 0,
			currentUserEmail: "",
			popupData: [[]],
			dataToBeUpdated: [],
			file: "",
			gameTab: "",
			loader: false,
			Key: "",
			row: "",
			game: "",
			selected: [],
			parent: "",
		};
	}
	getMuiTheme = () =>
		createMuiTheme({
			overrides: {
				TableHead: {
					root: {
						float: "left",
					},
				},
			},
		});

	componentWillMount() {
		// this.props.getWebOrders("new_keys");

		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				// console.log("MOUNTED1", user.email);

				this.setState({ currentUserEmail: user.email });
			}
		});

		// let url_array = this.props.history.location.pathname.split("/");
		// url_array = url_array.filter((el) => {
		// 	return el !== "";
		// });

		// // console.log("URL ARRAY", url_array);
		// if (url_array[1] === "Plugo") {
		// 	this.setState({ selectedTab: 100 });
		// } else if (url_array[1] === "Tacto") {
		// 	this.setState({ selectedTab: 200 });
		// } else if (url_array[1] === "Orboot") {
		// 	this.setState({ selectedTab: 300 });
		// }
		// let second_tab = url_array[url_array.length - 1];

		// kits_mapping.map((entry, idx) => {
		// 	second_tab = decodeURI(second_tab);
		// 	second_tab = second_tab.replace(" ", "_");
		// 	if (entry === second_tab.toLowerCase()) {
		// 		// console.log("149", entry, second_tab.toLowerCase(), idx);
		// 		this.setState({ selectedTab: idx + 1 });
		// 	} else if (entry === second_tab) {
		// 		this.setState({ selectedTab: idx + 1 });
		// 	}
		// });

		let newGameTab = this.checkUrlAndGetGameTab();
		this.props.getWebOrders(newGameTab);

		// setTimeout(() => {
		// 	this.setState({
		// 		table_data_unfiltered: this.props.contentData,
		// 		table_data: this.filterData(this.props.contentData),
		// 	});
		// }, 1000);
	}

	componentWillReceiveProps(nextProps) {
		let prev_gametab = this.state.gameTab;
		// let url_array = this.props.history.location.pathname.split("/");
		// url_array = url_array.filter((el) => {
		// 	return el !== "";
		// });
		// // console.log("URL changed = ", url_array, prev_gametab);

		// if (url_array[1] === "Plugo") {
		// 	this.setState({ selectedTab: 100 });
		// } else if (url_array[1] === "Tacto") {
		// 	this.setState({ selectedTab: 200 });
		// } else if (url_array[1] === "Orboot") {
		// 	this.setState({ selectedTab: 300 });
		// }
		// this.setState({ parent: url_array[1] });
		// let second_tab = url_array[url_array.length - 1];

		// let newGameTab = "";
		// // console.log(second_tab, this.state.selectedTab)
		// if (second_tab === "Numero") {
		// 	this.setState({ selectedTab: 7, gameTab: "numerov2" });
		// 	newGameTab = "numerov2";
		// } else if (second_tab === "Mcc2") {
		// 	this.setState({ selectedTab: 11, gameTab: "mccv2" });
		// 	newGameTab = "mccv2";
		// } else if (second_tab === "ISpy") {
		// 	this.setState({ selectedTab: 18, gameTab: "memory" });
		// 	newGameTab = "memory";
		// } else if (second_tab === "Tunes Common") {
		// 	this.setState({ selectedTab: 22, gameTab: "piano_common" });
		// 	newGameTab = "piano_common";
		// } else if (second_tab === "Letters Common") {
		// 	this.setState({ selectedTab: 24, gameTab: "alphabeto_common" });
		// 	newGameTab = "alphabeto_common";
		// } else if (second_tab === "Tacto Generic") {
		// 	this.setState({ selectedTab: 58, gameTab: "tacto_generic" });
		// 	newGameTab = "tacto_generic";
		// } else if (second_tab === "LocoJojo") {
		// 	this.setState({ selectedTab: 20, gameTab: "physics_prototype" });
		// 	newGameTab = "physics_prototype";
		// } else if (second_tab === "Letters Detective") {
		// 	this.setState({ selectedTab: 33, gameTab: "lettersDetective" });
		// 	newGameTab = "lettersDetective";
		// } else if (second_tab === "Tacto common") {
		// 	this.setState({ selectedTab: 58, gameTab: "Common" });
		// 	newGameTab = "Common";
		// } else {
		// 	kits_mapping.map((entry, idx) => {
		// 		second_tab = decodeURI(second_tab);
		// 		second_tab = second_tab.replace(" ", "_");

		// 		if (entry === second_tab.toLowerCase()) {
		// 			this.setState({
		// 				selectedTab: idx + 1,
		// 				gameTab: second_tab.toLowerCase(),
		// 			});
		// 			newGameTab = second_tab.toLowerCase();
		// 		} else if (entry === second_tab) {
		// 			this.setState({
		// 				selectedTab: idx + 1,
		// 				gameTab: second_tab,
		// 			});
		// 			newGameTab = second_tab;
		// 		}
		// 	});
		// }
		let newGameTab = this.checkUrlAndGetGameTab();
		// console.log("URL changed = ", prev_gametab, newGameTab);
		if (prev_gametab != newGameTab && newGameTab != "") {
			console.log("Fetch data again", newGameTab);

			this.props.getWebOrders(newGameTab);

			return;
		}
		// console.log(this.state.selectedTab, nextProps.contentData);
		setTimeout(() => {
			this.setState({
				table_data_unfiltered: nextProps.contentData,
				table_data: this.filterData(nextProps.contentData),
			});
		}, 100);
	}

	checkUrlAndGetGameTab = () => {
		let prev_gametab = this.state.gameTab;
		let url_array = this.props.history.location.pathname.split("/");
		url_array = url_array.filter((el) => {
			return el !== "";
		});
		// console.log("URL changed = ", url_array, prev_gametab);

		if (url_array[1] === "Plugo") {
			this.setState({ selectedTab: 100 });
		} else if (url_array[1] === "Tacto") {
			this.setState({ selectedTab: 200 });
		} else if (url_array[1] === "Orboot") {
			this.setState({ selectedTab: 300 });
		}
		this.setState({ parent: url_array[1] });
		let second_tab = url_array[url_array.length - 1];

		let newGameTab = "";
		// console.log(second_tab, this.state.selectedTab)
		if (second_tab === "Numero") {
			this.setState({ selectedTab: 7, gameTab: "numerov2" });
			newGameTab = "numerov2";
		} else if (second_tab === "Mcc2") {
			this.setState({ selectedTab: 11, gameTab: "mccv2" });
			newGameTab = "mccv2";
		} else if (second_tab === "ISpy") {
			this.setState({ selectedTab: 18, gameTab: "memory" });
			newGameTab = "memory";
		} else if (second_tab === "Tunes Common") {
			this.setState({ selectedTab: 22, gameTab: "piano_common" });
			newGameTab = "piano_common";
		} else if (second_tab === "Letters Common") {
			this.setState({ selectedTab: 24, gameTab: "alphabeto_common" });
			newGameTab = "alphabeto_common";
		} else if (second_tab === "Tacto Generic") {
			this.setState({ selectedTab: 58, gameTab: "tacto_generic" });
			newGameTab = "tacto_generic";
		} else if (second_tab === "LocoJojo") {
			this.setState({ selectedTab: 20, gameTab: "physics_prototype" });
			newGameTab = "physics_prototype";
		} else if (second_tab === "Letters Detective") {
			this.setState({ selectedTab: 33, gameTab: "lettersDetective" });
			newGameTab = "lettersDetective";
		} else if (second_tab === "Tacto common") {
			this.setState({ selectedTab: 58, gameTab: "Common" });
			newGameTab = "Common";
		} else {
			kits_mapping.map((entry, idx) => {
				second_tab = decodeURI(second_tab);
				second_tab = second_tab.replace(" ", "_");

				if (entry === second_tab.toLowerCase()) {
					this.setState({
						selectedTab: idx + 1,
						gameTab: second_tab.toLowerCase(),
					});
					newGameTab = second_tab.toLowerCase();
				} else if (entry === second_tab) {
					this.setState({
						selectedTab: idx + 1,
						gameTab: second_tab,
					});
					newGameTab = second_tab;
				}
			});
		}

		console.log("URL changed = ", prev_gametab, newGameTab);

		return newGameTab;
	};

	aud_play_pause() {
		var myAudio = document.getElementById("myTune");
		if (myAudio.paused) {
			myAudio.play();
		} else {
			myAudio.pause();
		}
	}

	//CHANGE HERE
	filterData = (data) => {
		// console.log(data, "------------------------------");
		let table_data = [];
		data.forEach((entry) => {
			//all plugo tabs except new keys
			if (this.state.selectedTab !== 1 && this.state.selectedTab < tactoGamesStartTab) {
				if (entry[23] && entry[23] === kits_mapping[this.state.selectedTab - 1]) {
					table_data.push(entry);
				}
			} else if (this.state.selectedTab > tactoGamesStartTab) {
				//all tacto games except new keys tacto
				if (entry[23] && entry[23] === kits_mapping[this.state.selectedTab - 1]) {
					table_data.push(entry);
				}
			} else if (this.state.selectedTab === tactoGamesStartTab) {
				//new keys tacto
				if (entry[24] === "tacto") table_data.push(entry);
			} else {
				// new keys plugo
				if (
					entry[24] === "plugo" && // change when adding a language
					entry[3] !== undefined &&
					entry[4] !== undefined &&
					entry[5] !== undefined &&
					entry[6] !== undefined
				)
					table_data.push(entry);
			}
		});
		return table_data;
	};

	onClosePopup = () => {
		this.setState({
			open: false,
			openR: false,
			openD: false,
			openA: false,
			openF: false,
		});
	};

	handleClickPopup = () => {
		if (admins.includes(this.state.currentUserEmail)) {
			this.state.selectedTab === 1 || this.state.selectedTab === tactoGamesStartTab
				? alert("Not allowed")
				: this.setState({ open: true });
		} else {
			alert("Not Allowed");
		}
	};

	handleClickPopupR = () => {
		this.setState({ openR: true });
	};

	handleFiles = async (files) => {
		let a = await this.uploadFile(files[0]);
		if (a === undefined) {
			var reader = new FileReader();
			let firestore = [];
			var firestore_data = {
				// English: "",
				// Chinese: "",
				// Dutch: "",
				// French: "",
				// German: "",
				// Japanese: "",
				// Korean: "",
				// Polish: "",
				// Swedish: "",
				// Danish: "",
				// Russian: "",
				// Norwegian: "",
				// Czech: "",
				// ChineseTraditional: "",
				// Spanish: "",
				// Slovenian: "",
				// PortugueseBrazil: "",
				// Italian: "",
				// Estonian: "",
				// Latvian: "",
				// Lithuanian: "",

				Key: "",
				game: "",
				IsAudio: "",
				isContentneedToBeTranslated: "",
				timestamp: "",
			};

			Object.keys(languages).forEach((lang) => {
				firestore_data[lang] = "";
			});

			// function csvToArray(text) {
			// 	let p = "",
			// 		row = [""],
			// 		ret = [row],
			// 		i = 0,
			// 		r = 0,
			// 		s = !0,
			// 		l;
			// 	for (l of text) {
			// 		if ('"' === l) {
			// 			if (s && l === p) row[i] += l;
			// 			s = !s;
			// 		} else if ("," === l && s) l = row[++i] = "";
			// 		else if ("\n" === l && s) {
			// 			if ("\r" === p) row[i] = row[i].slice(0, -1);
			// 			row = ret[++r] = [(l = "")];
			// 			i = 0;
			// 		} else row[i] += l;
			// 		p = l;
			// 	}
			// 	return ret;
			// }

			reader.onload = async (e) => {
				let csvData = csvToArray(reader.result);
				// console.log(csvData);
				// return;
				let first_row = csvData[0];
				csvData.shift();
				var language = "language";
				var obj = {};
				Object.keys(languages).forEach((lang) => {
					if (first_row.includes(lang)) {
						obj[language] = lang;
						return;
					}
				});
				// if (first_row.includes("Dutch")) {
				// 	obj[language] = "Dutch";
				// } else if (first_row.includes("Danish")) {
				// 	obj[language] = "Danish";
				// } else if (first_row.includes("Swedish")) {
				// 	obj[language] = "Swedish";
				// } else if (first_row.includes("Russian")) {
				// 	obj[language] = "Russian";
				// } else if (first_row.includes("Chinese")) {
				// 	obj[language] = "Chinese";
				// } else if (first_row.includes("Norwegian")) {
				// 	obj[language] = "Norwegian";
				// } else if (first_row.includes("Czech")) {
				// 	obj[language] = "Czech";
				// } else if (first_row.includes("ChineseTraditional")) {
				// 	obj[language] = "ChineseTraditional";
				// } else if (first_row.includes("Korean")) {
				// 	obj[language] = "Korean";
				// } else if (first_row.includes("French")) {
				// 	obj[language] = "French";
				// } else if (first_row.includes("German")) {
				// 	obj[language] = "German";
				// } else if (first_row.includes("Polish")) {
				// 	obj[language] = "Polish";
				// } else if (first_row.includes("Japanese")) {
				// 	obj[language] = "Japanese";
				// } else if (first_row.includes("Spanish")) {
				// 	obj[language] = "Spanish";
				// } else if (first_row.includes("Slovenian")) {
				// 	obj[language] = "Slovenian";
				// } else if (first_row.includes("PortugueseBrazil")) {
				// 	obj[language] = "PortugueseBrazil";
				// } else if (first_row.includes("Italian")) {
				// 	obj[language] = "Italian";
				// } else if (first_row.includes("Estonian")) {
				// 	obj[language] = "Estonian";
				// } else if (first_row.includes("Latvian")) {
				// 	obj[language] = "Latvian";
				// } else if (first_row.includes("Lithuanian")) {
				// 	obj[language] = "Lithuanian";
				// }

				let lang = obj[language];
				console.log(lang);
				let firstRowHasLanguage = false;
				Object.keys(languages).forEach((lang) => {
					if (first_row.includes(lang)) {
						firstRowHasLanguage = true;
					}
				});
				if (
					first_row.length === 5 &&
					firstRowHasLanguage
					// (first_row.includes("Dutch") ||
					// 	first_row.includes("Polish") ||
					// 	first_row.includes("Japanese") ||
					// 	first_row.includes("Norwegian") ||
					// 	first_row.includes("French") ||
					// 	first_row.includes("Korean") ||
					// 	first_row.includes("ChineseTraditional") ||
					// 	first_row.includes("Danish") ||
					// 	first_row.includes("German") ||
					// 	first_row.includes("Swedish") ||
					// 	first_row.includes("Czech") ||
					// 	first_row.includes("Russian") ||
					// 	first_row.includes("Chinese") ||
					// 	first_row.includes("Spanish") ||
					// 	first_row.includes("Slovenian") ||
					// 	first_row.includes("PortugueseBrazil") ||
					// 	first_row.includes("Italian") ||
					// 	first_row.includes("Estonian") ||
					// 	first_row.includes("Latvian") ||
					// 	first_row.includes("Lithuanian"))
				) {
					console.log("Csv type = singleUpload_csv");
					first_row.splice(2, 1);
					if (!_.isEqual(first_row, singleUpload_csv)) {
						alert("Wrong CSV Format, singleUpload_csv");
						this.setState({ openF: true });
					} else {
						this.setState({ loader: true });
						let count = 0;
						csvData.forEach(async (el) => {
							if (el.length > 1) {
								if (!el[2]) {
									el[2] = "";
								}
								let objectToUpdate;
								if (el[4] === "") objectToUpdate = { [lang]: el[2] };
								else objectToUpdate = { [lang]: [el[2], true] };
								// console.log(objectToUpdate)
								await db
									.collection("content-translation")
									.doc(el[0] + "_" + el[3])
									.update(objectToUpdate)
									.then((res) => {
										console.log(res, el[0] + "_" + el[3], lang, el[2]);
									})
									.then((response) => {
										if (response === undefined) count++;
										console.log(count, csvData.length);
										if (count === csvData.length - 1) {
											this.setState({ loader: false });
											window.location.reload();
										}
									})
									.catch((err) => console.log(err));
							}
						});
					}
				} else {
					console.log("Csv type = plugo -> checking further");
					//change this langugae whenever Chinese is included in tacto
					if (first_row.includes("Chinese")) {
						console.log("Includes chinese");
						if (!_.isEqual(first_row, plugo_csv)) {
							alert("Wrong CSV Format, plugo_csv");
							this.setState({ openF: true });
						} else {
							console.log("Csv type = plugo_csv");
							csvData.forEach((el) => {
								if (el.length > 1) {
									let gt = el[plugo_csv.indexOf("GT")].split(",");

									firestore_data = {
										Key: el[plugo_csv.indexOf("Key")],
										IsAudio: el[plugo_csv.indexOf("Content Type")],
										// English: el[plugo_csv.indexOf("English")],
										// Japanese: el[plugo_csv.indexOf("Japanese")],
										// French: el[plugo_csv.indexOf("French")],
										// German: el[plugo_csv.indexOf("German")],
										// Korean: el[plugo_csv.indexOf("German")],
										// Chinese: el[plugo_csv.indexOf("Chinese")],
										// Polish: el[plugo_csv.indexOf("Polish")],
										// Dutch: el[plugo_csv.indexOf("Dutch")],
										// Swedish: el[plugo_csv.indexOf("Swedish")],
										// Danish: el[plugo_csv.indexOf("Danish")],
										// Norwegian: el[plugo_csv.indexOf("Norwegian")],
										// ChineseTraditional:
										// 	el[plugo_csv.indexOf("ChineseTraditional")],
										// Russian: el[plugo_csv.indexOf("Russian")],
										// Czech: el[plugo_csv.indexOf("Czech")],
										// Spanish: el[plugo_csv.indexOf("Spanish")],
										// Slovenian: el[plugo_csv.indexOf("Slovenian")],
										// PortugueseBrazil: el[plugo_csv.indexOf("PortugueseBrazil")],
										// Italian: el[plugo_csv.indexOf("Italian")],
										game: el[plugo_csv.indexOf("Game")],
										voiceData:
											el[plugo_csv.indexOf("Character's Name & Voice")],
										createdAt: moment.unix(Date.now() / 1000).format("LLLL"),
										lowercaseKey: el[plugo_csv.indexOf("Key")].toLowerCase(),
									};
									Object.keys(languages).forEach((lang) => {
										if (plugo_csv.indexOf(lang) > -1) {
											firestore_data[lang] = el[plugo_csv.indexOf(lang)];
										}
									});
									var o = {};
									gt.forEach((lang) => {
										if (lang !== "") {
											firestore_data[lang] = [firestore_data[lang], true];
											// o[language] = item;
											// let lingo = o[language];
											// let l = firestore_data[lingo];
											// console.log("L = ", l);
											// l.push(true);
										}
									});
									for (const value in firestore_data) {
										if (firestore_data[value].length === 1) {
											firestore_data[value] = firestore_data[value][0];
										}
									}
									firestore.push(firestore_data);
								}
							});
						}
					} else if (
						first_row.length === 4 &&
						first_row.includes("Character's Name & Voice")
					) {
						if (!_.isEqual(first_row, voiceData_csv)) {
							alert("Wrong CSV Format, voiceData_csv");
							this.setState({ openF: true });
						} else {
							console.log("Csv type = voiceData_csv");
							let count = 0;
							let promises = [];
							csvData.forEach(async (el) => {
								let docId = el[0] + "_" + el[3];
								let updateData = { voiceData: el[2] };
								promises.push(updateDoc(docId, updateData));
								// await db
								// 	.collection("content-translation")
								// 	.doc(el[0] + "_" + el[3])
								// 	.update({ voiceData: el[2] })
								// 	.then((res) => {
								// 		console.log(res, el[0] + "_" + el[3], lang, el[2]);
								// 	})
								// 	.then((response) => {
								// 		if (response === undefined) count++;
								// 		console.log(count, csvData.length);
								// 		if (count === csvData.length - 1) {
								// 			this.setState({ loader: false });
								// 			// window.location.reload();
								// 		}
								// 	})
								// 	.catch((err) => console.log(err));
							});

							Promise.allSettled(promises).then((results) => {
								console.log("resolved all");
								this.setState({ loader: false });
								window.location.reload();
							});
						}
					} else {
						if (!_.isEqual(first_row, tacto_csv)) {
							alert("Wrong CSV Format, tacto_csv");
							this.setState({ openF: true });
						} else {
							console.log("Csv type = tacto_csv");
							// console.log(csvData);
							csvData.forEach((el) => {
								if (el[0] !== "") {
									let gt = el[tacto_csv.indexOf("GT")].split(",");
									firestore_data = {
										Key: el[tacto_csv.indexOf("Key")],
										game: el[tacto_csv.indexOf("Game")],
										IsAudio: el[tacto_csv.indexOf("Content Type")],
										voiceData:
											el[tacto_csv.indexOf("Character's Name & Voice")],
										createdAt: moment.unix(Date.now() / 1000).format("LLLL"),
										lowercaseKey: el[tacto_csv.indexOf("Key")].toLowerCase(),
										// English: el[2],
										// Japanese: [el[3]],
										// Russian: [el[6]],
										// Swedish: [el[4]],
										// Danish: [el[5]],
										// Spanish: [el[7]],
										// Slovenian: [el[8]],
										// PortugueseBrazil: [el[11]],
										// Italian: [el[12]],
										// Estonian: [el[13]],
										// Latvian: [el[14]],
										// Lithuanian: [el[15]],
										// Korean: [el[9]],
										// Norwegian: [el[10]],
									};

									Object.keys(languages).forEach((lang) => {
										if (tacto_csv.indexOf(lang) > -1) {
											firestore_data[lang] = el[tacto_csv.indexOf(lang)];
										}
									});

									console.log(firestore_data);
									var o = {};
									gt.forEach((lang) => {
										if (lang !== "") {
											firestore_data[lang] = [firestore_data[lang], true];
											// o[language] = item;
											// let lingo = o[language];
											// let l = firestore_data[lingo];
											// l.push(true);
										}
									});
									for (const value in firestore_data) {
										if (firestore_data[value].length === 1) {
											firestore_data[value] = firestore_data[value][0];
										}
									}
									firestore.push(firestore_data);
								}
							});
						}
					}

					if (firestore.length > 0) {
						let results = [];
						let duplicates = [];
						let woDuplicates = [];

						for (let i = 0; i < firestore.length; i++) {
							this.setState({ loader: true });
							await db
								.collection("content-translation")
								.doc(firestore[i].Key + "_" + firestore[i].game)
								.get()
								.then((document) => {
									if (document.exists && !duplicates.includes(firestore[i].Key)) {
										duplicates.push(firestore[i].Key);
									}
								});
							if (
								results.includes(firestore[i].Key) &&
								!duplicates.includes(firestore[i].Key)
							) {
								duplicates.push(firestore[i].Key);
							} else {
								results.push(firestore[i].Key);
								woDuplicates.push(firestore[i]);
							}
						}

						console.log("duplicates count = ", duplicates.length);
						if (duplicates.length > 0) {
							console.log("duplicates found = ", duplicates);
							console.log("woDuplicates found = ", woDuplicates);
							this.setState({
								loader: false,
								openA: true,
								duplicates: duplicates,
								woDuplicates: woDuplicates,
								allData: firestore,
							});
						} else {
							let count_final = 0;
							this.setState({ loader: true });

							let timer = firestore.length;
							// console.log(timer);

							console.log("firestore", firestore);
							let promises = [];

							firestore.forEach(async (data) => {
								if (data.Key) {
									let docId = data.Key + "_" + data.game;
									promises.push(insertDoc(docId, data));
									// console.log(data.Key + "_" + data.game);
									// await db
									// 	.collection("content-translation")
									// 	.doc(data.Key + "_" + data.game)
									// 	.set(data)
									// 	.then((res, err) => console.log(res))
									// 	.then((res) => {
									// 		if (res === undefined) count_final++;
									// 		console.log(count_final, firestore.length);
									// 		if (count_final === firestore.length - 1) {
									// 			this.setState({ loader: false });
									// 			// window.location.reload();
									// 		}
									// 	});
								}
							});

							Promise.allSettled(promises).then((results) => {
								console.log("resolved all");
								this.setState({ loader: false });
								window.location.reload();
							});
							// setTimeout(() => {
							// 	this.setState({ loader: false });
							// }, 250000);
							// return;

							// if (timer < 100) {
							// 	setTimeout(() => {
							// 		this.setState({ loader: false });
							// 		window.location.reload();
							// 	}, 40000);
							// }
							// if (timer > 100 && timer < 200) {
							// 	setTimeout(() => {
							// 		this.setState({ loader: false });
							// 		window.location.reload();
							// 	}, 75000);
							// }
							// if (timer > 200 && timer < 400) {
							// 	setTimeout(() => {
							// 		this.setState({ loader: false });
							// 		window.location.reload();
							// 	}, 150000);
							// }
							// if (timer > 400 && timer < 600) {
							// 	setTimeout(() => {
							// 		this.setState({ loader: false });
							// 		window.location.reload();
							// 	}, 250000);
							// }
							// if (timer > 600 && timer < 800) {
							// 	setTimeout(() => {
							// 		this.setState({ loader: false });
							// 		window.location.reload();
							// 	}, 360000);
							// }
							// if (timer > 800 && timer < 1000) {
							// 	setTimeout(() => {
							// 		this.setState({ loader: false });
							// 		window.location.reload();
							// 	}, 420000);
							// }
							// if (timer > 1000) {
							// 	setTimeout(() => {
							// 		this.setState({ loader: false });
							// 		window.location.reload();
							// 	}, 500000);
							// }
						}
					}
				}
			};
			reader.readAsText(files[0]);
		}
	};

	handleFilesOgg = async (file) => {
		this.setState({ loader: true });
		var bodyFormData = new FormData();

		bodyFormData.append("zip", file[0]);

		Axios({
			method: "post",
			url: "https://content-dash-api.playshifu.com/uploadToContentDash",
			// url: "http://localhost:9000/uploadToContentDash",
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then((response) => {
				this.setState({ loader: false });
				alert("OGG File Uploaded");
				window.location.reload();
			})
			.catch((err) => {
				console.log(err);
				alert("OGG File Error");
			});
	};

	handleArchive = (e) => {
		console.log(e);
		var reader1 = new FileReader();
		reader1.onload = function (e) {
			console.log("Here");
			console.log(reader1.readAsText(e[0]));
		};
	};

	handleFileInput = (e) => {
		this.setState({ file: e.target.files[0] });
	};

	uploadFile = async (file) => {
		const S3_BUCKET = `content-translation/${this.state.gameTab}`;
		const REGION = "ap-south-1";

		AWS.config.update({
			accessKeyId: "AKIA54HYZE24QQEEAWMS",
			secretAccessKey: "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",
		});

		const myBucket = new AWS.S3({
			params: { Bucket: S3_BUCKET },
			region: REGION,
		});
		const params = {
			ACL: "public-read",
			Body: file,
			Bucket: S3_BUCKET,
			Key: file.name,
		};

		myBucket.putObject(params).send((err) => {
			if (err) alert(err);
			else {
				console.log("Files Archived");
			}
		});
	};

	//CHANGE HERE
	editButtonClicked = (e, value, tableMeta) => {
		// console.log(tableMeta.rowData[tableMeta.columnIndex], tableMeta.rowData[tableMeta.columnIndex].includes(true))
		let checkbox;
		console.log("Table meta = ", tableMeta);
		if (typeof tableMeta.rowData[tableMeta.columnIndex] === null) {
			checkbox = true;
		} else if (
			tableMeta.rowData[tableMeta.columnIndex] !== null &&
			typeof tableMeta.rowData[tableMeta.columnIndex] === "object" &&
			tableMeta.rowData[tableMeta.columnIndex].includes(true)
		) {
			checkbox = true;
		} else if (
			tableMeta.rowData[tableMeta.columnIndex] !== null &&
			typeof tableMeta.rowData[tableMeta.columnIndex] === "object" &&
			!tableMeta.rowData[tableMeta.columnIndex].includes(true)
		) {
			checkbox = false;
		} else if (typeof tableMeta.rowData[tableMeta.columnIndex] === "string") {
			checkbox = false;
		}

		this.setState({
			openR: true,
			editPopupData: [
				tableMeta.rowData[tableMeta.columnIndex],
				tableMeta.columnData.label,
				tableMeta.rowData[0],
				tableMeta.rowData[13],
				tableMeta.rowData[tableMeta.rowData.length - 6], //game
				this.state.selectedTab,
				checkbox,
			],
		});
	};

	onDownload = (buildHead, buildBody, columns, data) => {
		// let i;
		// if (this.state.selectedTab < 57) {
		// 	i = 20;
		// } else {
		// 	i = 13;
		// }
		let finalData = [];
		data.map((row) => {
			let newRow = {};
			let dataColumns = row.data;
			let newColumns = [];
			dataColumns.map((column, index) => {
				if (
					typeof column === "object" &&
					column !== null &&
					index !== dataColumns.length - 1
				) {
					newColumns.push(column[0]);
				} else {
					newColumns.push(column);
				}
			});
			newRow.data = newColumns;
			finalData.push(newRow);
		});

		return "\uFEFF" + buildHead(columns) + buildBody(finalData);
	};

	openPopup = (e, tableMeta) => {
		console.log("deleting:", e);
		if (admins.includes(this.state.currentUserEmail)) {
			this.setState({
				openD: true,
				deleteData: tableMeta,
				deleteLookup: Object.keys(e.lookup),
			});
		} else {
			alert("Not Allowed");
			window.location.reload();
		}
	};

	// deleteDoc = (id) => {
	// 	return new Promise((resolve, reject) => {
	// 		db.collection("content-translation")
	// 			.doc(id)
	// 			.delete()
	// 			.then((res, err) => console.log(res))
	// 			.then((res) => {
	// 				console.log(res);
	// 				resolve();
	// 			});
	// 	});
	// };

	//CHANGE HERE
	onRowsDelete = async (e, tableMeta) => {
		if (this.state.selectedTab === 1) {
			alert("Not allowed");
			return;
		}

		// console.log("deleting: ", this.state.deleteData);

		this.setState({ openD: false, loader: true });
		// var missing = this.state.table_data.filter(
		// 	(i) => !this.state.deleteData.some((j) => i[0] === j[0])
		// );
		let missing = [];
		let promises = [];

		this.state.deleteLookup.forEach((dataIndex) => {
			missing.push(this.state.table_data[dataIndex]);
		});
		missing.forEach(async (data) => {
			// console.log("Deleting:", data);
			let key = data[0];
			let game = data[data.length - 6];
			let docId = key + "_" + game;
			console.log("deleting:", docId);
			promises.push(deleteDoc(docId));
			// await db
			// 	.collection("content-translation")
			// 	.doc(data[0] + "_" + data[20])
			// 	.delete()
			// 	.then((res, err) => console.log(res))
			// 	.then((res) => {
			// 		console.log(res);
			// 	});
		});

		Promise.allSettled(promises).then((results) => {
			// console.log("resolved all");
			this.setState({ loader: false });
			window.location.reload();
		});

		return;

		if (missing.length < 10) {
			setTimeout(() => {
				this.setState({ loader: false });
				window.location.reload();
			}, 5000);
		}
		if (missing.length > 10 && missing.length < 50) {
			setTimeout(() => {
				this.setState({ loader: false });
				window.location.reload();
			}, 15000);
		}
		if (missing.length > 50 && missing.length < 100) {
			setTimeout(() => {
				this.setState({ loader: false });
				window.location.reload();
			}, 30000);
		}
		if (missing.length > 100 && missing.length < 200) {
			setTimeout(() => {
				this.setState({ loader: false });
				window.location.reload();
			}, 55000);
		}
		if (missing.length > 200 && missing.length < 400) {
			setTimeout(() => {
				this.setState({ loader: false });
				window.location.reload();
			}, 150000);
		}
		if (missing.length > 400 && missing.length < 600) {
			setTimeout(() => {
				this.setState({ loader: false });
				window.location.reload();
			}, 250000);
		}
		if (missing.length > 600 && missing.length < 800) {
			setTimeout(() => {
				this.setState({ loader: false });
				window.location.reload();
			}, 330000);
		}
		if (missing.length > 800 && missing.length < 1000) {
			setTimeout(() => {
				this.setState({ loader: false });
				window.location.reload();
			}, 390000);
		}
		if (missing.length > 1000) {
			setTimeout(() => {
				this.setState({ loader: false });
				window.location.reload();
			}, 420000);
		}
	};

	uploadAll = () => {
		this.setState({ loader: true });

		// let timer = this.state.allData.length;
		// console.log(timer);

		let promises = [];

		this.state.allData.forEach(async (data) => {
			if (data.Key) {
				let docId = data.Key + "_" + data.game;
				promises.push(insertDoc(docId, data));
				// console.log(data.Key + "_" + data.game);
				// await db
				// 	.collection("content-translation")
				// 	.doc(data.Key + "_" + data.game)
				// 	.set(data)
				// 	.then((res, err) => console.log(res))
				// 	.then((res) => {
				// 		if (res) {
				// 			console.log(res);
				// 			// this.setState({loader: false})
				// 		}
				// 	});
			}
		});

		Promise.allSettled(promises).then((results) => {
			console.log("resolved all");
			this.setState({ loader: false });
			window.location.reload();
		});

		// return;

		// if (timer < 100) {
		// 	setTimeout(() => {
		// 		this.setState({ loader: false });
		// 		window.location.reload();
		// 	}, 40000);
		// }
		// if (timer > 100 && timer < 200) {
		// 	setTimeout(() => {
		// 		this.setState({ loader: false });
		// 		window.location.reload();
		// 	}, 75000);
		// }
		// if (timer > 200 && timer < 400) {
		// 	setTimeout(() => {
		// 		this.setState({ loader: false });
		// 		window.location.reload();
		// 	}, 150000);
		// }
		// if (timer > 400 && timer < 600) {
		// 	setTimeout(() => {
		// 		this.setState({ loader: false });
		// 		window.location.reload();
		// 	}, 250000);
		// }
		// if (timer > 600 && timer < 800) {
		// 	setTimeout(() => {
		// 		this.setState({ loader: false });
		// 		window.location.reload();
		// 	}, 360000);
		// }
		// if (timer > 800 && timer < 1000) {
		// 	setTimeout(() => {
		// 		this.setState({ loader: false });
		// 		window.location.reload();
		// 	}, 420000);
		// }
		// if (timer > 1000) {
		// 	setTimeout(() => {
		// 		this.setState({ loader: false });
		// 		window.location.reload();
		// 	}, 500000);
		// }
	};

	uploadWithoutDuplicates = () => {
		this.setState({ loader: true });

		let timer = this.state.woDuplicates.length;
		console.log(timer);

		this.state.woDuplicates.forEach(async (data) => {
			if (data.Key) {
				// console.log(data.Key + "_" + data.game);
				await db
					.collection("content-translation")
					.doc(data.Key + "_" + data.game)
					.set(data)
					.then((res, err) => console.log(res))
					.then((res) => {
						if (res) {
							console.log(res);
							// this.setState({loader: false})
						}
					});
			}
		});

		if (timer < 100) {
			setTimeout(() => {
				this.setState({ loader: false });
				window.location.reload();
			}, 40000);
		}
		if (timer > 100 && timer < 200) {
			setTimeout(() => {
				this.setState({ loader: false });
				window.location.reload();
			}, 75000);
		}
		if (timer > 200 && timer < 400) {
			setTimeout(() => {
				this.setState({ loader: false });
				window.location.reload();
			}, 150000);
		}
		if (timer > 400 && timer < 600) {
			setTimeout(() => {
				this.setState({ loader: false });
				window.location.reload();
			}, 250000);
		}
		if (timer > 600 && timer < 800) {
			setTimeout(() => {
				this.setState({ loader: false });
				window.location.reload();
			}, 360000);
		}
		if (timer > 800 && timer < 1000) {
			setTimeout(() => {
				this.setState({ loader: false });
				window.location.reload();
			}, 420000);
		}
		if (timer > 1000) {
			setTimeout(() => {
				this.setState({ loader: false });
				window.location.reload();
			}, 500000);
		}
	};

	playMusic = (e, a) => {
		console.log(e, a);
		var music = new Audio(a);
		music.play();
	};

	render() {
		const columns = [
			{
				name: "Key",
				label: "Key",
				options: {
					filter: false,
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								<p>{tableMeta.rowData[0]}</p>
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "English",
				label: "English",
				options: {
					filter: false,
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[1] === "object" ? (
									<div>
										<p>
											{tableMeta.rowData[1][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[1][1]}
											></audio>
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[1]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Japanese",
				label: "Japanese",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.selectedTab === tactoGamesStartTab ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[2] === "object" &&
								tableMeta.rowData[2].includes(true) &&
								tableMeta.rowData[2].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[2][0]}</p>
								) : typeof tableMeta.rowData[2] === "object" &&
								  !tableMeta.rowData[2].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[2][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[2][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[2] === "object" &&
								  tableMeta.rowData[2].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[2][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[2].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[2]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "French",
				label: "French",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.parent === "Tacto" ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[3] === "object" &&
								tableMeta.rowData[3].includes(true) &&
								tableMeta.rowData[3].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[3][0]}</p>
								) : typeof tableMeta.rowData[3] === "object" &&
								  !tableMeta.rowData[3].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[3][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[3][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[3] === "object" &&
								  tableMeta.rowData[3].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[3][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[3].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[3]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "German",
				label: "German",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.parent === "Tacto" ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[4] === "object" &&
								tableMeta.rowData[4].includes(true) &&
								tableMeta.rowData[4].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[4][0]}</p>
								) : typeof tableMeta.rowData[4] === "object" &&
								  !tableMeta.rowData[4].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[4][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[4][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[4] === "object" &&
								  tableMeta.rowData[4].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[4][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[4].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[4]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Korean",
				label: "Korean",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.selectedTab === tactoGamesStartTab ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[5] === "object" &&
								tableMeta.rowData[5].includes(true) &&
								tableMeta.rowData[5].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[5][0]}</p>
								) : typeof tableMeta.rowData[5] === "object" &&
								  !tableMeta.rowData[5].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[5][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[5][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[5] === "object" &&
								  tableMeta.rowData[5].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[5][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[5].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[5]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Chinese",
				label: "Chinese",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.parent === "Tacto" ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[6] === "object" &&
								tableMeta.rowData[6].includes(true) &&
								tableMeta.rowData[6].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[6][0]}</p>
								) : typeof tableMeta.rowData[6] === "object" &&
								  !tableMeta.rowData[6].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[6][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[6][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[6] === "object" &&
								  tableMeta.rowData[6].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[6][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[6].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[6]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Polish",
				label: "Polish",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.parent === "Tacto" ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[7] === "object" &&
								tableMeta.rowData[7].includes(true) &&
								tableMeta.rowData[7].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[7][0]}</p>
								) : typeof tableMeta.rowData[7] === "object" &&
								  !tableMeta.rowData[7].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[7][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[7][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[7] === "object" &&
								  tableMeta.rowData[7].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[7][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[7].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[7]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Dutch",
				label: "Dutch",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.parent === "Tacto" ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[8] === "object" &&
								tableMeta.rowData[8].includes(true) &&
								tableMeta.rowData[8].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[8][0]}</p>
								) : typeof tableMeta.rowData[8] === "object" &&
								  !tableMeta.rowData[8].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[8][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[8][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[8] === "object" &&
								  tableMeta.rowData[8].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[8][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[8].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[8]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Swedish",
				label: "Swedish",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.selectedTab === 57 ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[9] === "object" &&
								tableMeta.rowData[9].includes(true) &&
								tableMeta.rowData[9].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[9][0]}</p>
								) : typeof tableMeta.rowData[9] === "object" &&
								  !tableMeta.rowData[9].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[9][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[9][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[9] === "object" &&
								  tableMeta.rowData[9].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[9][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[9].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[9]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Danish",
				label: "Danish",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.selectedTab === 57 ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[10] === "object" &&
								tableMeta.rowData[10].includes(true) &&
								tableMeta.rowData[10].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[10][0]}</p>
								) : typeof tableMeta.rowData[10] === "object" &&
								  !tableMeta.rowData[10].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[10][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[10][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[10] === "object" &&
								  tableMeta.rowData[10].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[10][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[10].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[10]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Norwegian",
				label: "Norwegian",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.selectedTab === tactoGamesStartTab ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[11] === "object" &&
								tableMeta.rowData[11].includes(true) &&
								tableMeta.rowData[11].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[11][0]}</p>
								) : typeof tableMeta.rowData[11] === "object" &&
								  !tableMeta.rowData[11].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[11][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[11][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[11] === "object" &&
								  tableMeta.rowData[11].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[11][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[11].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[11]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "ChineseTraditional",
				label: "ChineseTraditional",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.parent === "Tacto" ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[12] === "object" &&
								tableMeta.rowData[12].includes(true) &&
								tableMeta.rowData[12].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[12][0]}</p>
								) : typeof tableMeta.rowData[12] === "object" &&
								  !tableMeta.rowData[12].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[12][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[12][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[12] === "object" &&
								  tableMeta.rowData[12].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[12][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[12].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[12]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Russian",
				label: "Russian",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.selectedTab === tactoGamesStartTab ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[13] === "object" &&
								tableMeta.rowData[13].includes(true) &&
								tableMeta.rowData[13].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[13][0]}</p>
								) : typeof tableMeta.rowData[13] === "object" &&
								  !tableMeta.rowData[13].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[13][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[13][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[13] === "object" &&
								  tableMeta.rowData[13].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[13][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[13].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[13]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Czech",
				label: "Czech",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.parent === "Tacto" ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[14] === "object" &&
								tableMeta.rowData[14].includes(true) &&
								tableMeta.rowData[14].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[14][0]}</p>
								) : typeof tableMeta.rowData[14] === "object" &&
								  !tableMeta.rowData[14].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[14][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[14][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[14] === "object" &&
								  tableMeta.rowData[14].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[14][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[14].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[14]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Spanish",
				label: "Spanish",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.selectedTab === tactoGamesStartTab ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[15] === "object" &&
								tableMeta.rowData[15].includes(true) &&
								tableMeta.rowData[15].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[15][0]}</p>
								) : typeof tableMeta.rowData[15] === "object" &&
								  !tableMeta.rowData[15].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[15][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[15][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[15] === "object" &&
								  tableMeta.rowData[15].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[15][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[15].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[15]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Slovenian",
				label: "Slovenian",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.selectedTab === tactoGamesStartTab ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[16] === "object" &&
								tableMeta.rowData[16].includes(true) &&
								tableMeta.rowData[16].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[16][0]}</p>
								) : typeof tableMeta.rowData[16] === "object" &&
								  !tableMeta.rowData[16].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[16][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[16][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[16] === "object" &&
								  tableMeta.rowData[16].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[16][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[16].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[16]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "PortugueseBrazil",
				label: "PortugueseBrazil",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.selectedTab === tactoGamesStartTab ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[17] === "object" &&
								tableMeta.rowData[17].includes(true) &&
								tableMeta.rowData[17].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[17][0]}</p>
								) : typeof tableMeta.rowData[17] === "object" &&
								  !tableMeta.rowData[17].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[17][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[17][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[17] === "object" &&
								  tableMeta.rowData[17].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[17][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[17].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[17]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Italian",
				label: "Italian",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.selectedTab === tactoGamesStartTab ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[18] === "object" &&
								tableMeta.rowData[18].includes(true) &&
								tableMeta.rowData[18].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[18][0]}</p>
								) : typeof tableMeta.rowData[18] === "object" &&
								  !tableMeta.rowData[18].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[18][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[18][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[18] === "object" &&
								  tableMeta.rowData[18].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[18][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[18].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[18]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Estonian",
				label: "Estonian",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.selectedTab < tactoGamesStartTab ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[19] === "object" &&
								tableMeta.rowData[19].includes(true) &&
								tableMeta.rowData[19].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[19][0]}</p>
								) : typeof tableMeta.rowData[19] === "object" &&
								  !tableMeta.rowData[19].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[19][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[19][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[19] === "object" &&
								  tableMeta.rowData[19].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[19][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[19].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[19]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Latvian",
				label: "Latvian",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.selectedTab < tactoGamesStartTab ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[20] === "object" &&
								tableMeta.rowData[20].includes(true) &&
								tableMeta.rowData[20].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[20][0]}</p>
								) : typeof tableMeta.rowData[20] === "object" &&
								  !tableMeta.rowData[20].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[20][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[20][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[20] === "object" &&
								  tableMeta.rowData[20].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[20][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[20].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[20]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Lithuanian",
				label: "Lithuanian",
				options: {
					filter: false,
					display:
						this.state.selectedTab === 1 ||
						this.state.selectedTab < tactoGamesStartTab ||
						ops.includes(this.state.currentUserEmail)
							? "false"
							: "true",
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{typeof tableMeta.rowData[21] === "object" &&
								tableMeta.rowData[21].includes(true) &&
								tableMeta.rowData[21].length === 2 ? (
									<p style={{ color: "blue" }}>{tableMeta.rowData[21][0]}</p>
								) : typeof tableMeta.rowData[21] === "object" &&
								  !tableMeta.rowData[21].includes(true) ? (
									<div>
										<p>
											{tableMeta.rowData[21][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[21][1]}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : typeof tableMeta.rowData[21] === "object" &&
								  tableMeta.rowData[21].length === 3 ? (
									<div>
										<p style={{ color: "blue" }}>
											{tableMeta.rowData[21][0]}
											<br></br>
											<audio
												controls
												id="myTune"
												src={tableMeta.rowData[21].find((v) =>
													typeof v === "string" ? v.includes("ogg") : null
												)}
											></audio>
											{/* <Button type="button" onClick={() => this.aud_play_pause()}> <PlayArrowIcon /></Button> */}
										</p>
									</div>
								) : (
									<p>{tableMeta.rowData[21]}</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Content Type",
				label: "Content Type",
				options: {
					filter: true,
					customBodyRender: (value, tableMeta, updateValue) => {
						if (tableMeta.rowData[22] === "Both") {
							return (
								<div
									style={{
										display: "flex",
										flexDirection: "row",
									}}
								>
									<p>{tableMeta.rowData[22]}</p>
									{admins.includes(this.state.currentUserEmail) ? (
										<IconButton
											onClick={(e) =>
												this.editButtonClicked(e, value, tableMeta)
											}
											style={{
												width: "5px",
												height: "5px",
												margin: "10px 0 0 5px",
											}}
											variant="contained"
											color="primary"
										>
											{this.state.selectedTab === 1 ||
											this.state.selectedTab === tactoGamesStartTab ? null : (
												<EditIcon />
											)}
										</IconButton>
									) : (
										<div />
									)}
								</div>
							);
						}
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{tableMeta.rowData[22] === "No" ||
								tableMeta.rowData[22] === "Text" ? (
									<p>Text</p>
								) : (
									<p>VO</p>
								)}
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Game",
				label: "Game",
				options: {
					filter: true,
					sort: true,
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								<p>{tableMeta.rowData[23]}</p>
								{/* {admins.includes(this.state.currentUserEmail) ? (
                  <IconButton
                    onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
                    style={{
                      width: "5px",
                      height: "5px",
                      margin: "10px 0 0 5px",
                    }}
                    variant="contained"
                    color="primary"
                  >
                    {this.state.selectedTab === 1 ? null : <EditIcon />}
                  </IconButton>
                ) : (
                  <div />
                )} */}
							</div>
						);
					},
				},
			},
			{
				name: "Dummy",
				label: "Dummy",
				options: {
					display: false,
					filter: false,
					viewColumns: false,
					download: true,
				},
			},
			{
				name: "Time",
				label: "Time",
				options: {
					display: false,
					filter: false,
					customBodyRender: (value, tableMeta, updateValue) => {
						return <p>{tableMeta.rowData[25]}</p>;
					},
				},
			},
			{
				name: "Content to be Translated",
				label: "Content to be Translated",
				options: {
					display:
						this.state.selectedTab === 1 ||
						this.state.selectedTab === tactoGamesStartTab
							? "true"
							: "false",
					filter: true,
					customBodyRender: (value, tableMeta, updateValue) => {
						return <p>{tableMeta.rowData[26].join(",")}</p>;
					},
				},
			},
			{
				name: "Character's Name & Voice",
				label: "Character's Name & Voice",
				options: {
					filter:
						this.state.selectedTab === 1 ||
						this.state.selectedTab === tactoGamesStartTab
							? false
							: true,
					sort: true,
					filterOptions: {
						renderValue: (val) => {
							if (val === "" || val === null || val === undefined) {
								return "(empty)";
							}
							return val;
						},
					},
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								<p>{tableMeta.rowData[27]}</p>
								{admins.includes(this.state.currentUserEmail) ? (
									<IconButton
										onClick={(e) => this.editButtonClicked(e, value, tableMeta)}
										style={{
											width: "5px",
											height: "5px",
											margin: "10px 0 0 5px",
										}}
										variant="contained"
										color="primary"
									>
										{this.state.selectedTab === 1 ||
										this.state.selectedTab === tactoGamesStartTab ? null : (
											<EditIcon />
										)}
									</IconButton>
								) : (
									<div />
								)}
							</div>
						);
					},
				},
			},
			{
				name: "Has No Audio",
				label: "Has No Audio",
				options: {
					setCellProps: () => ({
						style: {
							minWidth: "240px",
							maxWidth: "240px",
							whiteSpace: "normal",
							wordWrap: "break-word",
							overflow: "hidden",
							responsive: "scroll",
							overflowX: "scroll",
						},
					}),
					display:
						this.state.selectedTab === 1 ||
						this.state.selectedTab === tactoGamesStartTab
							? "excluded"
							: "true",
					filter:
						this.state.selectedTab === 1 ||
						this.state.selectedTab === tactoGamesStartTab
							? false
							: true,
					customBodyRender: (value, tableMeta, updateValue) => {
						return <p>{tableMeta.rowData[28].join(",")}</p>;
					},
				},
			},
		];

		let duplicateKeys = this.state.duplicates;

		const options = {
			filterType: "checkbox",
			fixedHeader: true,
			// filter: false,
			print: false,
			viewColumns:
				this.state.selectedTab === 1 ||
				this.state.selectedTab === tactoGamesStartTab ||
				others.includes(this.state.currentUserEmail)
					? false
					: true,
			search: others.includes(this.state.currentUserEmail) ? false : true,
			filter: others.includes(this.state.currentUserEmail) ? false : true,
			selectableRows:
				this.state.selectedTab === 1 ||
				this.state.selectedTab === tactoGamesStartTab ||
				ops.includes(this.state.currentUserEmail) ||
				devs.includes(this.state.currentUserEmail) ||
				others.includes(this.state.currentUserEmail)
					? "none"
					: "multiple",
			onRowsSelect: this.onRowsSelect,
			download:
				(ops.includes(this.state.currentUserEmail) &&
					(this.state.selectedTab === 1 ||
						this.state.selectedTab === tactoGamesStartTab ||
						this.state.selectedTab === 35)) ||
				admins.includes(this.state.currentUserEmail)
					? true
					: false,
			onDownload: this.onDownload,
			downloadOptions: {
				filename: `${
					this.state.gameTab +
					"_" +
					this.state.currentUserEmail +
					"_" +
					new Date().toLocaleString()
				}.csv`,
				seperator: ",",
				filterOptions: {
					useDisplayedColumnsOnly: true,
					useDisplayedRowsOnly: true,
				},
			},
			onRowsDelete: this.openPopup,
			customToolbar: admins.includes(this.state.currentUserEmail)
				? () => <CustomToolbar handleClick={this.handleClickPopup} />
				: null,
		};

		if (
			this.state.selectedTab === 0 ||
			this.state.selectedTab === 100 ||
			this.state.selectedTab === 200 ||
			this.state.selectedTab === 300 ||
			this.state.parent === "1. PLUGO" ||
			this.state.parent === "2. ORBOOT" ||
			this.state.parent === "3. TACTO"
		) {
			return (
				<div>
					<img style={{ marginTop: "150px" }} src={require("../../../assets/logo.png")} />

					<span
						style={{
							display: "block",
							padding: "10px",
							fontFamily: "revert",
							fontSize: "larger",
						}}
					>
						Welcome to Content Dashboard
					</span>
				</div>
			);
		}
		if (this.state.loader === true) {
			return (
				<div>
					<img
						style={{ height: "200px", marginTop: "175px" }}
						src="https://flevix.com/wp-content/uploads/2020/01/Preloader.gif"
					/>
					<p style={{ marginTop: "-50px" }}>Please do not refresh</p>
				</div>
			);
		}
		return (
			<div>
				<Popup
					dataToBeUpdated={this.state.dataToBeUpdated}
					open={this.state.open}
					handleClose={this.onClosePopup}
					selectedTab={this.state.gameTab}
					tabNumber={this.state.selectedTab}
					onCancelOrder={this.onCancelOrder}
				/>
				<PopupRakuten
					open={this.state.openR}
					data={this.state.editPopupData}
					handleClose={this.onClosePopup}
					onCancelOrder={this.onCancelOrder}
				/>
				<FormControl>
					{this.state.selectedTab === 1 ? (
						<InputLabel
							style={{
								fontVariant: "all-petite-caps",
								fontSize: "x-large",
							}}
						>
							<div>
								<p
									style={{
										textAlign: "center",
										margin: "-25px 0px",
										fontSize: "33px",
										color: "black",
										border: "1px solid",
									}}
								>
									NewKeys
								</p>
							</div>
						</InputLabel>
					) : (
						<InputLabel
							style={{
								fontVariant: "all-petite-caps",
								fontSize: "x-large",
							}}
						>
							<div>
								<p
									style={{
										textAlign: "center",
										margin: "-25px -50px",
										fontSize: "33px",
										color: "black",
										border: "1px solid",
									}}
								>
									{" "}
									{kits_mapping[this.state.selectedTab - 1]}{" "}
								</p>
							</div>
						</InputLabel>
					)}
				</FormControl>
				{this.state.selectedTab === 1 ||
				this.state.selectedTab === tactoGamesStartTab ||
				ops.includes(this.state.currentUserEmail) ||
				devs.includes(this.state.currentUserEmail) ||
				others.includes(this.state.currentUserEmail) ? null : (
					<div>
						<div style={{ float: "right" }}>
							<ReactFileReader
								handleFiles={(e) => this.handleFilesOgg(e)}
								fileTypes={".zip"}
							>
								<Button
									type="submit"
									// style={{ marginLeft: "90%", marginBottom: "0.5%" }}
									variant="contained"
									color="primary"
									className="btn"
								>
									Upload OGG
								</Button>
							</ReactFileReader>
						</div>
						<div style={{ float: "right", marginRight: "10px" }}>
							<ReactFileReader
								handleFiles={(e) => this.handleFiles(e)}
								fileTypes={".csv"}
							>
								<Button
									type="submit"
									// style={{ marginLeft: "90%", marginBottom: "0.5%" }}
									variant="contained"
									color="primary"
									className="btn"
								>
									Upload CSV
								</Button>
							</ReactFileReader>
						</div>
					</div>
				)}
				{this.state.selectedTab === 1 ||
				this.state.selectedTab === tactoGamesStartTab ||
				ops.includes(this.state.currentUserEmail) ||
				devs.includes(this.state.currentUserEmail) ||
				others.includes(this.state.currentUserEmail) ? null : (
					<div style={{ float: "left", marginRight: "10px" }}>
						<Button variant="contained" style={{ height: "33px", marginRight: "10px" }}>
							<input type="file" onChange={this.handleFileInput} />
						</Button>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							className="btn"
							onClick={() => this.uploadFile(this.state.file)}
						>
							Archive CSV
						</Button>
					</div>
				)}
				<Dialog open={this.state.openD} aria-labelledby="form-dialog-title">
					<DialogTitle style={{ marginTop: "2%" }} id="form-dialog-title">
						Delete Row{" "}
					</DialogTitle>
					<DialogContent>
						<DialogContentText>Are you sure you want to delete?</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.onClosePopup} color="primary">
							Close
						</Button>
						<Button onClick={(e) => this.onRowsDelete(e)} color="primary">
							Delete
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.openF} aria-labelledby="form-dialog-title">
					<DialogTitle style={{ marginTop: "2%" }} id="form-dialog-title">
						Wrong CSV Format
					</DialogTitle>
					<DialogContent>
						<DialogContentText>Please Check the CSV Format</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.onClosePopup} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.openA} aria-labelledby="form-dialog-title">
					<DialogTitle style={{ marginTop: "2%" }} id="form-dialog-title">
						Duplicate Keys{" "}
					</DialogTitle>
					<DialogContent>
						<DialogContentText variant="h6">
							These are the duplicate Keys :<br></br>
							{duplicateKeys.map((qs, idx) => (
								<div key={idx} style={{ color: "red" }}>
									{qs.split(",").map((item) => (
										<span
											style={{
												fontWeight: 100,
												fontFamily: "monospace",
											}}
										>
											{item}
											<br />
										</span>
									))}
								</div>
							))}
							<br></br>
							<br></br>
							<div style={{ marginTop: "-25px" }}>
								Are you sure you want to add these?
							</div>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.onClosePopup} color="primary">
							Close
						</Button>
						<Button onClick={this.uploadAll} color="primary">
							Yes
						</Button>
						<Button onClick={this.uploadWithoutDuplicates} color="primary">
							No
						</Button>
					</DialogActions>
				</Dialog>
				<div
					className="DataTable"
					style={{ marginTop: "50px", tableLayout: "fixed", width: "100%" }}
				>
					<MuiThemeProvider theme={this.getMuiTheme()}>
						<MUIDataTable
							data={this.state.table_data}
							columns={columns}
							options={options}
						/>
					</MuiThemeProvider>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	contentData: state.webOrders.contentData,
});

const mapDispatchToProps = (dispatch) => {
	return {
		getWebOrders: (game = null) => {
			// dispatch(getWebOrders);
			getWebOrders(dispatch, game);
		},
		deleteUserDetails: (data) => {
			deleteUserDetails(dispatch, data);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataTable));
