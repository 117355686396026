import React from "react";
import "./App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import HistoryTable from "./Pages/Dashboard/HistoryTable";

import Sidebar from "./Pages/Sidebar/Sidebar";
function App() {
	return (
		<div className="App">
			{/* <header className="App-header"></header> */}
			<BrowserRouter>
				<Switch>
					<Route exact path="/" render={() => <Redirect to="/login" />} />
					<Route path="/login">
						<Login />
					</Route>
					<Route path="/ContentDash">
						<Dashboard />
					</Route>
					<Route path="/history">
						<HistoryTable />
					</Route>

					<Route path="/app">
						<Sidebar />
					</Route>
					<Route path="/app/:id">
						<Sidebar />
					</Route>
					<Route path="/web">
						<Sidebar />
					</Route>
					<Route path="/web/:id">
						<Sidebar />
					</Route>
					<Redirect from="*" to="/login" />
				</Switch>
			</BrowserRouter>
		</div>
	);
}

export default App;
