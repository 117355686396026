import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarStyles = {
  iconButton: {},
};

class CustomToolbar extends React.Component {
  //   handleClick = () => {
  //     console.log("clicked on icon!");
  //   };

  render() {
    const { classes } = this.props;
    // console.log("classes", classes);

    return (
      <React.Fragment>
        <Tooltip title={"Add a new Row"}>
          <IconButton className={classes.iconButton} onClick={this.props.handleClick}>
            <AddIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default withStyles({ name: "CustomToolbar" })(CustomToolbar);
