import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Alert from "@material-ui/lab/Alert";
import { firebase } from "../firebase";
import {
	// cancelOrder,
	cancelItem,
	updateUserDetails,
	updateShipmentLink,
} from "../actions/orderActions";
import { connect } from "react-redux";
const db = firebase.firestore();

class PopupRakuten extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			fieldUpdated: false,
			quantity: 1,
			token: "",
			translation: "",
			checked: false,
			alert: false,
		};
	}
	componentWillUnmount() {
		this.setState({
			open: this.props.open,
			translation: this.props.data[0],
		});
	}
	componentWillReceiveProps(nextProps) {
		console.log("USE EFECT CALLED", nextProps, typeof nextProps.data[0]);
		let var_ogg;
		if (
			nextProps.data[0] !== null &&
			typeof nextProps.data[0] === "object" &&
			nextProps.data[0].length > 1 &&
			!nextProps.data[0].includes(true)
		) {
			var_ogg = nextProps.data[0].find((a) => a.includes("ogg"));
		}
		// console.log(var_ogg);
		if (
			typeof nextProps.data[0] === "object" &&
			nextProps.data[0] !== null &&
			nextProps.data[0] !== "" &&
			nextProps.data[0].length === 3
		) {
			this.setState({
				open: nextProps.open,
				translation: nextProps.data[0][0],
				checked: nextProps.data[6],
			});
		} else if (
			typeof nextProps.data[0] === "object" &&
			nextProps.data[0] !== null &&
			nextProps.data[0] !== "" &&
			nextProps.data[0].length === 2 &&
			nextProps.data[0].includes(true)
		) {
			this.setState({
				open: nextProps.open,
				checked: nextProps.data[6],
				translation: nextProps.data[0][0],
			});
		} else if (
			typeof nextProps.data[0] === "object" &&
			nextProps.data[0] !== null &&
			nextProps.data[0] !== "" &&
			nextProps.data[0].length === 2 &&
			var_ogg !== undefined
		) {
			this.setState({
				open: nextProps.open,
				translation: nextProps.data[0][0],
				checked: nextProps.data[6],
			});
		} else {
			this.setState({
				open: nextProps.open,
				translation: nextProps.data[0],
				checked: nextProps.data[6],
			});
		}
	}

	handleClose = () => {
		this.setState({ alert: false, open: false, checked: false });
	};
	closeDuplicate = () => {
		this.setState({ openA: false });
	};

	onChangeQuantity = (e) => {
		e.preventDefault();
		if (this.props.data[1] === "Key") {
			db.collection("content-translation")
				.doc(e.target.value + "_" + this.props.data[4])
				.get()
				.then((document) => {
					if (document.exists) {
						this.setState({ openA: true, alert: true });
					} else {
						this.setState({ alert: false });
					}
				});
		}
		// console.log("TARGET VALUE", e.target.value);
		this.setState({ translation: e.target.value });
	};

	handleChange = (event) => {
		this.setState({ checked: event.target.checked });
	};
	onUpdateDetails = (e) => {
		e.preventDefault();
		let updated_object = this.props.data;
		updated_object[0] = this.state.translation.trim();
		updated_object[3] = this.state.checked;
		updated_object[4] = this.props.data[4];
		updated_object[5] = this.props.data[5];
		console.log("UPDATE", this.props.data);
		this.setState({ open: false });
		this.props.updateShipmentLink(updated_object);
		// setTimeout(() => {
		// 	console.log("UPDATE DELAY", this.state.open);
		// }, 1000);
		// setTimeout(() => {
		//   window.location.href = "/sidebar";
		// }, 1000);
	};

	render() {
		return (
			<div>
				<Dialog open={this.state.openA} aria-labelledby="form-dialog-title">
					<DialogTitle style={{ marginTop: "2%" }} id="form-dialog-title">
						Duplicate Key
					</DialogTitle>
					<DialogContent>
						<DialogContentText variant="h6">
							This is a duplicate Key. This key already exists in this game sheet. The
							content will be overwritten if you choose to add it.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.closeDuplicate} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={this.state.open}
					onClose={this.props.handleClose}
					aria-labelledby="form-dialog-title"
					maxWidth="sm"
					fullWidth={true}
				>
					<DialogTitle style={{ marginTop: "2%" }} id="form-dialog-title">
						Update Translation{" "}
					</DialogTitle>
					<DialogContent>
						<DialogContentText>{this.props.data[1]}</DialogContentText>
						{this.state.alert === true && this.props.data[1] === "Key" ? (
							<div>
								<Alert severity="error" variant="outlined">
									{" "}
									The key is duplicate
								</Alert>
								<TextField
									autoFocus
									onChange={(e) => this.onChangeQuantity(e)}
									margin="dense"
									id="outlined-multiline-static"
									label="Text"
									type="name"
									value={this.state.translation}
									fullWidth
									multiline
									variant="outlined"
									rows={5}
								/>
							</div>
						) : (
							<TextField
								autoFocus
								onChange={(e) => this.onChangeQuantity(e)}
								margin="dense"
								id="outlined-multiline-static"
								label="Text"
								type="name"
								value={this.state.translation}
								fullWidth
								multiline
								variant="outlined"
								rows={5}
							/>
						)}
						{this.props.data[1] === "Key" ||
						this.props.data[1] === "Content Type" ||
						this.props.data[1] === "Character's Name & Voice" ||
						this.props.data[1] === "English" ? null : (
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.checked}
										onChange={this.handleChange}
										value={false}
										name="checkedB"
										color="primary"
									/>
								}
								label="Google Translated"
							/>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.handleClose} color="primary">
							Close
						</Button>
						<Button onClick={(e) => this.onUpdateDetails(e)} color="primary">
							Update
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	webOrders: state.webOrders.webOrders,
});
const mapDispatchToProps = (dispatch) => {
	return {
		cancelItem: (orderId, sku) => {
			cancelItem(dispatch, orderId, sku);
		},
		updateUserDetails: (details, orderId) => {
			updateUserDetails(dispatch, details, orderId);
		},
		updateShipmentLink: (obj) => {
			updateShipmentLink(dispatch, obj);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupRakuten);
