import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import DialogContentText from "@material-ui/core/DialogContentText";
import Alert from "@material-ui/lab/Alert";
import {
	// cancelOrder,
	cancelItem,
	updateUserDetails,
	updateShipmentLink,
	// updateDetails,
} from "../actions/orderActions";
import { connect } from "react-redux";
import { firebase } from "../firebase";
import { insertDoc, updateDoc } from "../helpers/db";
const db = firebase.firestore();
const tab1 = 57;

class Popup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ordersArray: [],
			open: false,
			gameTab: "",
			fieldUpdated: false,
			english: "",
			chinese: "",
			french: "",
			german: "",
			japanese: "",
			korean: "",
			dutch: "",
			polish: "",
			swedish: "",
			danish: "",
			russian: "",
			norwegian: "",
			czech: "",
			chineseTraditional: "",
			spanish: "",
			slovenian: "",
			portuguesebrazil: "",
			italian: "",
			estonian: "",
			latvian: "",
			lithuanian: "",
			key: "",
			game: "",
			content: "",
			voiceData: "",
			openA: false,
			alert: false,
			tabNumber: null,
		};
	}

	componentWillReceiveProps(nextProps) {
		// console.log("POPUP RECEIVED PROPS", nextProps);

		this.setState({
			open: nextProps.open,
			gameTab: nextProps.selectedTab,
			tabNumber: nextProps.tabNumber,
		});
	}

	componentWillUnmount() {
		this.setState({ open: false });
	}

	handleClose = () => {
		this.setState({ open: false });
		setTimeout(() => {
			console.log("HANDLE CLOSE CALLED", this.state.open);
		}, 1000);
	};

	closeDuplicate = () => {
		this.setState({ openA: false });
	};

	onChangeChinese = (e) => {
		this.setState({ chinese: e.target.value });
	};
	onChangeEnglish = (e) => {
		this.setState({ english: e.target.value });
	};
	onChangeDutch = (e) => {
		this.setState({ dutch: e.target.value });
	};
	onChangePolish = (e) => {
		this.setState({ polish: e.target.value });
	};
	onChangeFrench = (e) => {
		this.setState({ french: e.target.value });
	};
	onChangeGerman = (e) => {
		this.setState({ german: e.target.value });
	};
	onChangeJapnese = (e) => {
		this.setState({ japanese: e.target.value });
	};
	onChangeSwedish = (e) => {
		this.setState({ swedish: e.target.value });
	};
	onChangeKorean = (e) => {
		this.setState({ korean: e.target.value });
	};
	onChangeDansih = (e) => {
		this.setState({ danish: e.target.value });
	};
	onChangeRussian = (e) => {
		this.setState({ russian: e.target.value });
	};
	onChangeNorwegian = (e) => {
		this.setState({ norwegian: e.target.value });
	};
	onChangeCzech = (e) => {
		this.setState({ czech: e.target.value });
	};
	onChangeChineseTraditional = (e) => {
		this.setState({ chineseTraditional: e.target.value });
	};
	onChangeSpanish = (e) => {
		this.setState({ spanish: e.target.value });
	};
	onChangeSlovenian = (e) => {
		this.setState({ slovenian: e.target.value });
	};
	onChangePortuguesebrazil = (e) => {
		this.setState({ portuguesebrazil: e.target.value });
	};
	onChangeItalian = (e) => {
		this.setState({ italian: e.target.value });
	};
	onChangeEstonian = (e) => {
		this.setState({ estonian: e.target.value });
	};
	onChangeLatvian = (e) => {
		this.setState({ latvian: e.target.value });
	};
	onChangeLithuanian = (e) => {
		this.setState({ lithuanian: e.target.value });
	};
	onChangeVoiceData = (e) => {
		this.setState({ voiceData: e.target.value });
	};
	onChangeKey = (e) => {
		db.collection("content-translation")
			.doc(e.target.value + "_" + this.state.gameTab)
			.get()
			.then((document) => {
				if (document.exists) {
					this.setState({ openA: true, alert: true });
				} else {
					this.setState({ alert: false });
				}
			});
		this.setState({ key: e.target.value });
	};
	onChangeGame = (e) => {
		this.setState({ game: e.target.value });
	};
	handleChange = (e) => {
		this.setState({ content: e.target.value });
	};

	//Capitalize all keys*
	onUpdateDetails = (e) => {
		e.preventDefault();
		console.log(this.state);
		if (
			this.state.key === "" ||
			this.state.key === null ||
			this.state.english === "" ||
			this.state.english === null
		) {
			alert("Fields Key and english are needed");
			return;
		}
		//
		let docId = this.state.key.trim() + "_" + this.state.gameTab;
		let data = {
			Key: this.state.key.trim(),
			English: this.state.english.trim(),
			Chinese: this.state.chinese.trim(),
			Dutch: this.state.dutch.trim(),
			French: this.state.french.trim(),
			German: this.state.german.trim(),
			Japanese: this.state.japanese.trim(),
			Korean: this.state.korean.trim(),
			Polish: this.state.polish.trim(),
			Swedish: this.state.swedish.trim(),
			Danish: this.state.danish.trim(),
			Russian: this.state.russian.trim(),
			Norwegian: this.state.norwegian.trim(),
			Czech: this.state.czech.trim(),
			ChineseTraditional: this.state.chineseTraditional.trim(),
			Spanish: this.state.spanish.trim(),
			Slovenian: this.state.slovenian.trim(),
			PortugueseBrazil: this.state.portuguesebrazil.trim(),
			Italian: this.state.italian.trim(),
			Estonian: this.state.estonian,
			Latvian: this.state.latvian,
			Lithuanian: this.state.lithuanian,
			IsAudio: this.state.content,
			game: this.state.gameTab,
			voiceData: this.state.voiceData.trim(),
			createdAt: moment.unix(Date.now() / 1000).format("LLLL"),
			lowercaseKey: this.state.key.toLowerCase().trim(),
		};
		insertDoc(docId, data).then((res) => {
			console.log("Doc added");
			window.location.reload();
		});
		// if (this.state.tabNumber < tab1) {

		// 	// this.props.updateUserDetails();
		// } else {
		// 	updateDoc(docId, data).then((res) => {
		// 		console.log("Doc added");
		// 	});
		// 	this.props.updateUserDetails({
		// 		Key: this.state.key,
		// 		English: this.state.english,
		// 		Japanese: this.state.japanese,
		// 		Russian: this.state.russian,
		// 		Danish: this.state.danish,
		// 		Swedish: this.state.swedish,
		// 		Spanish: this.state.spanish,
		// 		Slovenian: this.state.slovenian,
		// 		Korean: this.state.korean,
		// 		Norwegian: this.state.norwegian,
		// 		PortugueseBrazil: this.state.portuguesebrazil,
		// 		Italian: this.state.italian,
		// 		Estonian: this.state.estonian,
		// 		Latvian: this.state.latvian,
		// 		Lithuanian: this.state.lithuanian,
		// 		IsAudio: this.state.content,
		// 		game: this.state.gameTab,
		// 		voiceData: this.state.voiceData,
		// 		createdAt: moment.unix(Date.now() / 1000).format("LLLL"),
		// 		lowercaseKey: this.state.key.toLowerCase(),
		// 	});
		// }

		this.handleClose();
		// setTimeout(() => {
		//   window.location.href = "/sidebar";
		// }, 1000);
	};

	render() {
		return (
			<div>
				<Dialog open={this.state.openA} aria-labelledby="form-dialog-title">
					<DialogTitle style={{ marginTop: "2%" }} id="form-dialog-title">
						Duplicate Key
					</DialogTitle>
					<DialogContent>
						<DialogContentText variant="h6">
							This is a duplicate Key. This key already exists in this game sheet. The
							content will be overwritten if you choose to add it.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.closeDuplicate} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={this.state.open}
					onClose={this.props.handleClose}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle style={{ marginTop: "2%" }} id="form-dialog-title">
						Add/Edit Row{" "}
					</DialogTitle>
					<DialogContent>
						{this.state.alert === true ? (
							<div>
								<Alert severity="error" variant="outlined">
									{" "}
									The key is duplicate
								</Alert>
								<TextField
									autoFocus
									onChange={(e) => this.onChangeKey(e)}
									margin="dense"
									id="key"
									label="Key"
									type="key"
									value={this.state.key}
									fullWidth
								/>
							</div>
						) : (
							<TextField
								autoFocus
								onChange={(e) => this.onChangeKey(e)}
								margin="dense"
								id="key"
								label="Key"
								type="key"
								value={this.state.key}
								fullWidth
							/>
						)}
						<TextField
							autoFocus
							onChange={(e) => this.onChangeEnglish(e)}
							margin="dense"
							id="english"
							label="English"
							type="english"
							value={this.state.english}
							fullWidth
						/>
						<TextField
							autoFocus
							onChange={(e) => this.onChangeJapnese(e)}
							margin="dense"
							id="japanese"
							label="Japanese"
							type="japanese"
							value={this.state.japanese}
							fullWidth
						/>
						{this.state.tabNumber < tab1 ? (
							<TextField
								autoFocus
								onChange={(e) => this.onChangeFrench(e)}
								margin="dense"
								id="french"
								label="French"
								type="french"
								value={this.state.french}
								fullWidth
							/>
						) : null}

						{this.state.tabNumber < tab1 ? (
							<TextField
								autoFocus
								onChange={(e) => this.onChangeGerman(e)}
								margin="dense"
								id="german"
								label="German"
								type="german"
								value={this.state.german}
								fullWidth
							/>
						) : null}

						<TextField
							autoFocus
							onChange={(e) => this.onChangeKorean(e)}
							margin="dense"
							id="korean"
							label="Korean"
							type="korean"
							value={this.state.korean}
							fullWidth
						/>

						{this.state.tabNumber < tab1 ? (
							<TextField
								autoFocus
								onChange={(e) => this.onChangeChinese(e)}
								margin="dense"
								id="chinese"
								label="Chinese"
								type="chinese"
								value={this.state.name}
								fullWidth
							/>
						) : null}

						{this.state.tabNumber < tab1 ? (
							<TextField
								autoFocus
								onChange={(e) => this.onChangePolish(e)}
								margin="dense"
								id="polish"
								label="Polish"
								type="polish"
								value={this.state.polish}
								fullWidth
							/>
						) : null}

						{this.state.tabNumber < tab1 ? (
							<TextField
								autoFocus
								onChange={(e) => this.onChangeDutch(e)}
								margin="dense"
								id="dutch"
								label="Dutch"
								type="dutch"
								value={this.state.dutch}
								fullWidth
							/>
						) : null}

						<TextField
							autoFocus
							onChange={(e) => this.onChangeSwedish(e)}
							margin="dense"
							id="swedish"
							label="Swedish"
							type="swedish"
							value={this.state.swedish}
							fullWidth
						/>

						<TextField
							autoFocus
							onChange={(e) => this.onChangeDansih(e)}
							margin="dense"
							id="danish"
							label="Danish"
							type="danish"
							value={this.state.danish}
							fullWidth
						/>
						<TextField
							autoFocus
							onChange={(e) => this.onChangeRussian(e)}
							margin="dense"
							id="russian"
							label="Russian"
							type="russian"
							value={this.state.russian}
							fullWidth
						/>
						<TextField
							autoFocus
							onChange={(e) => this.onChangeNorwegian(e)}
							margin="dense"
							id="norwegian"
							label="Norwegian"
							type="norwegian"
							value={this.state.norwegian}
							fullWidth
						/>
						{this.state.tabNumber < tab1 ? (
							<TextField
								autoFocus
								onChange={(e) => this.onChangeCzech(e)}
								margin="dense"
								id="czech"
								label="Czech"
								type="czech"
								value={this.state.czech}
								fullWidth
							/>
						) : null}
						{this.state.tabNumber < tab1 ? (
							<TextField
								autoFocus
								onChange={(e) => this.onChangeChineseTraditional(e)}
								margin="dense"
								id="chineseTraditional"
								label="ChineseTraditional"
								type="chineseTraditional"
								value={this.state.chineseTraditional}
								fullWidth
							/>
						) : null}
						<TextField
							autoFocus
							onChange={(e) => this.onChangeSpanish(e)}
							margin="dense"
							id="spanish"
							label="spanish"
							type="spanish"
							value={this.state.spanish}
							fullWidth
						/>
						<TextField
							autoFocus
							onChange={(e) => this.onChangeSlovenian(e)}
							margin="dense"
							id="slovenian"
							label="slovenian"
							type="slovenian"
							value={this.state.slovenian}
							fullWidth
						/>
						<TextField
							autoFocus
							onChange={(e) => this.onChangePortuguesebrazil(e)}
							margin="dense"
							id="portuguesebrazil"
							label="portuguesebrazil"
							type="portuguesebrazil"
							value={this.state.portuguesebrazil}
							fullWidth
						/>
						<TextField
							autoFocus
							onChange={(e) => this.onChangeItalian(e)}
							margin="dense"
							id="italian"
							label="italian"
							type="italian"
							value={this.state.italian}
							fullWidth
						/>
						{this.state.tabNumber >= tab1 ? (
							<>
								<TextField
									autoFocus
									onChange={(e) => this.onChangeEstonian(e)}
									margin="dense"
									id="estonian"
									label="estonian"
									type="estonian"
									value={this.state.estonian}
									fullWidth
								/>
								<TextField
									autoFocus
									onChange={(e) => this.onChangeLatvian(e)}
									margin="dense"
									id="latvian"
									label="latvian"
									type="latvian"
									value={this.state.latvian}
									fullWidth
								/>
								<TextField
									autoFocus
									onChange={(e) => this.onChangeLithuanian(e)}
									margin="dense"
									id="lithuanian"
									label="lithuanian"
									type="lithuanian"
									value={this.state.lithuanian}
									fullWidth
								/>
							</>
						) : null}

						<TextField
							autoFocus
							// onChange={(e) => this.onChangeGame(e)}
							margin="dense"
							id="game"
							label="Game"
							type="game"
							value={this.state.gameTab}
							fullWidth
							disabled
						/>
						<TextField
							autoFocus
							onChange={(e) => this.onChangeVoiceData(e)}
							margin="dense"
							id="VoiceData"
							label="Voice Data"
							type="VoiceData"
							value={this.state.voiceData}
							fullWidth
						/>
						<InputLabel style={{ marginTop: "20px" }}>Content Type</InputLabel>
						<Select
							label="Content Type"
							style={{ width: "90px" }}
							id="content"
							value={this.state.content}
							onChange={(e) => this.handleChange(e)}
						>
							<MenuItem value={"Text"}>Text</MenuItem>
							<MenuItem value={"VO"}>VO</MenuItem>
							<MenuItem value={"Both"}>Both</MenuItem>
						</Select>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.handleClose} color="primary">
							Close
						</Button>
						<Button onClick={(e) => this.onUpdateDetails(e)} color="primary">
							Add/Edit
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	webOrders: state.webOrders.webOrders,
});
const mapDispatchToProps = (dispatch) => {
	return {
		cancelItem: (orderId, sku) => {
			cancelItem(dispatch, orderId, sku);
		},
		updateUserDetails: (data) => {
			updateUserDetails(dispatch, data);
		},
		updateShipmentLink: (link, sku, orderId) => {
			updateShipmentLink(dispatch, link, sku, orderId);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
