import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { withRouter, Redirect } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import { firebase } from "../../firebase";
import { Button } from "@material-ui/core";
import MultiMenus from "./MultiMenus";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import HistoryIcon from "@material-ui/icons/History";
import Tooltip from "@material-ui/core/Tooltip";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "list-item",
	},
	appBar: {
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: "flex-end",
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: -drawerWidth,
	},
	contentShift: {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
}));

const menus = [
	{
		label: "1. PLUGO",
		layer: 1,
		submenu: [
			{
				label: "P Common",
				layer: 2,
				parent: "Plugo",
				submenu: [
					{
						label: "New Keys",
						value: "/newKeys",
						parent: "Plugo/Common",
						layer: 3,
					},
					{
						label: "Generic",
						value: "/generic",
						parent: "Plugo/Common",
						layer: 3,
					},
				],
			},
			{
				label: "Count",
				layer: 2,
				parent: "Plugo",
				submenu: [
					{
						label: "Count Common",
						value: "/count",
						layer: 3,
						parent: "Plugo/Count",
					},
					{
						label: "Theo Common",
						value: "/theoCommon",
						layer: 3,
						parent: "Plugo/Count",
					},
					{
						label: "Theo",
						value: "/theo",
						layer: 3,
						parent: "Plugo/Count",
					},
					{
						label: "Numero Common",
						value: "/numeroCommon",
						layer: 3,
						parent: "Plugo/Count",
					},
					{
						label: "Numero",
						value: "/numero",
						layer: 3,
						parent: "Plugo/Count",
					},
					{
						label: "Space",
						value: "/space",
						layer: 3,
						parent: "Plugo/Count",
					},
					{
						label: "Dana",
						value: "/dana",
						layer: 3,
						parent: "Plugo/Count",
					},
					{
						label: "Mcc",
						value: "/mcc",
						layer: 3,
						parent: "Plugo/Count",
					},
					{
						label: "Mcc2",
						value: "/mcc2",
						layer: 3,
						parent: "Plugo/Count",
					},
					{
						label: "CM1",
						value: "/cm1",
						layer: 3,
						parent: "Plugo/Count",
					},
					{
						label: "CM2",
						value: "/cm2",
						layer: 3,
						parent: "Plugo/Count",
					},
					{
						label: "CM3",
						value: "/cm3",
						layer: 3,
						parent: "Plugo/Count",
					},
				],
			},
			{
				label: "Link",
				layer: 2,
				parent: "Plugo",
				submenu: [
					{
						label: "Link Common",
						value: "/link",
						layer: 3,
						parent: "Plugo/Link",
					},
					{
						label: "Gears",
						value: "/gears",
						layer: 3,
						parent: "Plugo/Link",
					},
					{
						label: "Wordhunt",
						value: "/wordhunt",
						layer: 3,
						parent: "Plugo/Link",
					},
					{
						label: "ISpy",
						value: "/memory",
						layer: 3,
						parent: "Plugo/Link",
					},
					{
						label: "Frog",
						value: "/frog",
						layer: 3,
						parent: "Plugo/Link",
					},
					{
						label: "LocoJojo",
						value: "/physics",
						layer: 3,
						parent: "Plugo/Link",
					},
					{
						label: "Pipes",
						value: "/pipes",
						layer: 3,
						parent: "Plugo/Link",
					},
				],
			},
			{
				label: "Tunes",
				layer: 2,
				parent: "Plugo",
				submenu: [
					{
						label: "Tunes Common",
						value: "/piano",
						layer: 3,
						parent: "Plugo/Piano",
					},
					{
						label: "Tory",
						value: "/tory",
						layer: 3,
						parent: "Plugo/Piano",
					},
				],
			},
			{
				label: "Letters",
				layer: 2,
				parent: "Plugo",
				submenu: [
					{
						label: "Letters Common",
						value: "/alphabeto",
						layer: 3,
						parent: "Plugo/Letters",
					},
					{
						label: "Cat",
						value: "/cat",
						layer: 3,
						parent: "Plugo/Letters",
					},
					{
						label: "Fish",
						value: "/fish",
						layer: 3,
						parent: "Plugo/Letters",
					},
					{
						label: "Dino",
						value: "/dino",
						layer: 3,
						parent: "Plugo/Letters",
					},
					{
						label: "Puzzle",
						value: "/puzzle",
						layer: 3,
						parent: "Plugo/Letters",
					},
					{
						label: "Oko",
						value: "/oko1",
						layer: 3,
						parent: "Plugo/Letters",
					},
					{
						label: "Oko2",
						value: "/oko2",
						layer: 3,
						parent: "Plugo/Letters",
					},
					{
						label: "Caveman",
						value: "/caveman",
						layer: 3,
						parent: "Plugo/Letters",
					},
					{
						label: "Crossword",
						value: "/crossword",
						layer: 3,
						parent: "Plugo/Letters",
					},
					{
						label: "Letters Detective",
						value: "/detective",
						layer: 3,
						parent: "Plugo/Letters",
					},
					{
						label: "LM1",
						value: "/lm1",
						layer: 3,
						parent: "Plugo/Letters",
					},
					{
						label: "LM2",
						value: "/lm2",
						layer: 3,
						parent: "Plugo/Letters",
					},
					{
						label: "LM3",
						value: "/lm3",
						layer: 3,
						parent: "Plugo/Letters",
					},
				],
			},
			{
				label: "Slingshot",
				layer: 2,
				parent: "Plugo",
				submenu: [
					{
						label: "Catapult Common",
						value: "/catapult",
						layer: 3,
						parent: "Plugo/Catapult",
					},
					{
						label: "Iggy",
						value: "/iggy",
						layer: 3,
						parent: "Plugo/Catapult",
					},
					{
						label: "Tizi",
						value: "/tizi",
						layer: 3,
						parent: "Plugo/Catapult",
					},
					{
						label: "Waterfall",
						value: "/waterfall",
						layer: 3,
						parent: "Plugo/Catapult",
					},
				],
			},
			{
				label: "Animals",
				layer: 2,
				parent: "Plugo",
				submenu: [
					{
						label: "Animals Common",
						value: "/animals",
						layer: 3,
						parent: "Plugo/Animals",
					},
					{
						label: "Eli",
						value: "/eli",
						layer: 3,
						parent: "Plugo/Animals",
					},
					{
						label: "Vaaria",
						value: "/vaaria",
						layer: 3,
						parent: "Plugo/Animals",
					},
					{
						label: "Animals LM",
						value: "/animalslm",
						layer: 3,
						parent: "Plugo/Animals",
					},
				],
			},
			{
				label: "Detective",
				layer: 2,
				parent: "Plugo",
				submenu: [
					{
						label: "Detective Common",
						value: "/detective",
						layer: 3,
						parent: "Plugo/Detective",
					},
					{
						label: "PDet1",
						value: "/pdet1",
						layer: 3,
						parent: "Plugo/Detective",
					},
					{
						label: "PDet2",
						value: "/pdet2",
						layer: 3,
						parent: "Plugo/Detective",
					},
					{
						label: "PDet3",
						value: "/pdet3",
						layer: 3,
						parent: "Plugo/Detective",
					},
				],
			},
			{
				label: "Farm",
				layer: 2,
				parent: "Plugo",
				submenu: [
					{
						label: "Farm Common",
						value: "/farm",
						layer: 3,
						parent: "Plugo/Farm",
					},
					{
						label: "Happy Barn",
						value: "/happybarn",
						layer: 3,
						parent: "Plugo/Farm",
					},
					{
						label: "Farm lm Otis",
						value: "/farmotis",
						layer: 3,
						parent: "Plugo/Farm",
					},
					{
						label: "Farm lm2",
						value: "/farmlm2",
						layer: 3,
						parent: "Plugo/Farm",
					},
				],
			},
		],
	},
	{
		label: "2. ORBOOT",
		layer: 1,
		submenu: [
			{
				label: "Orboot Keys",
				layer: 2,
				parent: "Orboot",
			},
			{
				label: "Earth",
				layer: 2,
				parent: "Orboot",
			},
			{
				label: "Mars",
				layer: 2,
				parent: "Orboot",
			},
			{
				label: "Dinos",
				layer: 2,
				parent: "Orboot",
			},
		],
	},
	{
		label: "3. TACTO",
		layer: 1,
		submenu: [
			{
				label: "Tacto Common",
				layer: 2,
				parent: "Tacto",
				submenu: [
					{
						label: "New Keys Tacto",
						value: "/newKeysTacto",
						parent: "Tacto/Generic",
						layer: 3,
					},
					{
						label: "Tacto common",
						value: "/genericTacto",
						parent: "Tacto/Generic",
						layer: 3,
					},
				],
			},
			{
				label: "Classics",
				layer: 2,
				parent: "Tacto",
				submenu: [
					{
						label: "Retro Common",
						value: "/classicscommon",
						parent: "Tacto/Classics",
						layer: 3,
					},
					{
						label: "Retro Ludo",
						value: "/ludo",
						parent: "Tacto/Classics",
						layer: 3,
					},
					{
						label: "Retro TrollsAndLadders",
						value: "/tnl",
						parent: "Tacto/Classics",
						layer: 3,
					},
					{
						label: "Retro Checkers",
						value: "/checkers",
						parent: "Tacto/Classics",
						layer: 3,
					},
					{
						label: "Retro TicTacToe",
						value: "/tictactoe",
						parent: "Tacto/Classics",
						layer: 3,
					},
				],
			},
			{
				label: "Laser",
				layer: 2,
				parent: "Tacto",
				submenu: [
					{
						label: "Laser Common",
						value: "/lasercommon",
						parent: "Tacto/Laser",
						layer: 3,
					},
					{
						label: "Laser SanaAndPuppy",
						value: "/sana",
						parent: "Tacto/Laser",
						layer: 3,
					},
					{
						label: "Laser Detective",
						value: "/laserDetective",
						parent: "Tacto/Laser",
						layer: 3,
					},
					{
						label: "Laser MazeProd",
						value: "/maze",
						parent: "Tacto/Laser",
						layer: 3,
					},
					{
						label: "Laser LaserSlime",
						value: "/slimeSaga",
						parent: "Tacto/Laser",
						layer: 3,
					},
				],
			},
			{
				label: "Chess",
				layer: 2,
				parent: "Tacto",
				submenu: [
					{
						label: "Chess Common",
						parent: "Tacto/Chess",
						layer: 3,
						value: "/chessCommon",
					},
					{
						label: "Chess ChessGame",
						parent: "Tacto/Chess",
						layer: 3,
						value: "/chessLM",
					},
					// {
					//   label: "Chess goes Bananas",
					//   value: "/chessgoesBananas",
					//   parent: "Tacto/Chess",
					//   layer: 3,
					// },
					// {
					//   label: "Candyland",
					//   value: "/candyland",
					//   parent: "Tacto/Chess",
					//   layer: 3,
					// },
					// {
					//   label: "Solitaire",
					//   value: "/solitaire",
					//   parent: "Tacto/Chess",
					//   layer: 3,
					// },
				],
			},
			{
				label: "Coding",
				layer: 2,
				parent: "Tacto",
				submenu: [
					{
						label: "Coding Common",
						value: "/codingCommon",
						layer: 3,
						parent: "Tacto/Coding",
					},
					{
						label: "Coding CodingAnimals",
						value: "/cg1",
						layer: 3,
						parent: "Tacto/Coding",
					},
					{
						label: "Coding CodingGameTwoProto",
						value: "/cg2",
						layer: 3,
						parent: "Tacto/Coding",
					},
					{
						label: "Coding CodeOnTheRun",
						value: "/cg3",
						layer: 3,
						parent: "Tacto/Coding",
					},
					{
						label: "Coding CodingGameFour",
						value: "/cg4",
						layer: 3,
						parent: "Tacto/Coding",
					},
				],
			},
			{
				label: "Electronics",
				layer: 2,
				parent: "Tacto",
				submenu: [
					{
						label: "Electronics Common",
						value: "/electronics",
						layer: 3,
						parent: "Tacto/Electronics",
					},
					{
						label: "Electronics ElectronicsG",
						value: "/eg1",
						layer: 3,
						parent: "Tacto/Electronics",
					},
					{
						label: "Electronics EGTwo",
						value: "/eg2",
						layer: 3,
						parent: "Tacto/Electronics",
					},
					{
						label: "Electronics ElectronicsGameThree",
						value: "/eg3",
						layer: 3,
						parent: "Tacto/Electronics",
					},
					{
						label: "Electronics ElectronicsLearnMode",
						value: "/eglm",
						layer: 3,
						parent: "Tacto/Electronics",
					},
				],
			},
			{
				label: "T-Dino",
				layer: 2,
				parent: "Tacto",
				submenu: [
					{
						label: "Dinos Common",
						value: "/dino",
						layer: 3,
						parent: "Tacto/Dino",
					},
					{
						label: "Dinos TactoDino",
						value: "/Dinopedia",
						layer: 3,
						parent: "Tacto/Dino",
					},
					// {
					//   label: "DinO'Clock Adventures",
					//   value: "/dinoAdventures",
					//   layer: 3,
					//   parent: "Tacto/Dino",
					// },
					// {
					//   label: "Trapped in Dino-Land",
					//   value: "/dinoland",
					//   layer: 3,
					//   parent: "Tacto/Dino",
					// },
				],
			},
			{
				label: "Tacto Doctor",
				layer: 2,
				parent: "Tacto",
				submenu: [
					{
						label: "Doctor Common",
						value: "/doctorcommon",
						layer: 3,
						parent: "Tacto/Doctor",
					},
					{
						label: "Doctor DoctorOne",
						value: "/tdoc1",
						layer: 3,
						parent: "Tacto/Doctor",
					},
					{
						label: "Doctor DoctorTwo",
						value: "/tdoc2",
						layer: 3,
						parent: "Tacto/Doctor",
					},
					{
						label: "Doctor DoctorThree",
						value: "/tdoc3",
						layer: 3,
						parent: "Tacto/Doctor",
					},
				],
			},
		],
	},
	// {
	// 	label: "4. WEBSITE",
	// 	type: "web",
	// 	layer: 1,
	// 	submenu: [
	// 		{
	// 			label: "Desktop",
	// 			value: "desktop",
	// 			type: "web",
	// 			layer: 2,
	// 			parent: "web",
	// 			submenu: [
	// 				{
	// 					label: "Home",
	// 					value: "home",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Orboot",
	// 					value: "orboot",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Orboot Earth",
	// 					value: "orboot_earth",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Orboot Dinos",
	// 					value: "orboot_dinos",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Orboot Mars",
	// 					value: "orboot_mars",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Plugo",
	// 					value: "plugo",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Tacto",
	// 					value: "tacto",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Shop",
	// 					value: "shop",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Cart Overlay",
	// 					value: "cart_overlay",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Cart Overlay Reco Products",
	// 					value: "cart_overlay_reco_products",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Footer",
	// 					value: "footer",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "About Us",
	// 					value: "about_us",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Schools",
	// 					value: "schools",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Gifts",
	// 					value: "gifts",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Contact Us",
	// 					value: "contact_us",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "TnC",
	// 					value: "tnc",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Press Kit",
	// 					value: "press_kit",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Employee List",
	// 					value: "employee_list",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Privacy Policy",
	// 					value: "privacy_policy",
	// 					type: "web",
	// 					parent: "desktop",
	// 					layer: 3,
	// 				},
	// 			],
	// 		},
	// 		{
	// 			label: "Mobile",
	// 			value: "mobile",
	// 			type: "web",
	// 			layer: 2,
	// 			parent: "web",
	// 			submenu: [
	// 				{
	// 					label: "Home",
	// 					value: "home",
	// 					type: "web",
	// 					parent: "mobile",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Orboot",
	// 					value: "orboot",
	// 					type: "web",
	// 					parent: "mobile",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Orboot Earth",
	// 					value: "orboot_earth",
	// 					type: "web",
	// 					parent: "mobile",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Orboot Dinos",
	// 					value: "orboot_dinos",
	// 					type: "web",
	// 					parent: "mobile",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Orboot Mars",
	// 					value: "orboot_mars",
	// 					type: "web",
	// 					parent: "mobile",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Plugo",
	// 					value: "plugo",
	// 					type: "web",
	// 					parent: "mobile",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Tacto",
	// 					value: "tacto",
	// 					type: "web",
	// 					parent: "mobile",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Shop",
	// 					value: "shop",
	// 					type: "web",
	// 					parent: "mobile",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Cart Overlay",
	// 					value: "cart_overlay",
	// 					type: "web",
	// 					parent: "mobile",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Footer",
	// 					value: "footer",
	// 					type: "web",
	// 					parent: "mobile",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "About Us",
	// 					value: "about_us",
	// 					type: "web",
	// 					parent: "mobile",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Schools",
	// 					value: "schools",
	// 					type: "web",
	// 					parent: "mobile",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Gifts",
	// 					value: "gifts",
	// 					type: "web",
	// 					parent: "mobile",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Contact Us",
	// 					value: "contact_us",
	// 					type: "web",
	// 					parent: "mobile",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "TnC",
	// 					value: "tnc",
	// 					type: "web",
	// 					parent: "mobile",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Press Kit",
	// 					value: "press_kit",
	// 					type: "web",
	// 					parent: "mobile",
	// 					layer: 3,
	// 				},
	// 				{
	// 					label: "Privacy Policy",
	// 					value: "privacy_policy",
	// 					type: "web",
	// 					parent: "mobile",
	// 					layer: 3,
	// 				},
	// 			],
	// 		},
	// 	],
	// },
];

function Sidebar(props) {
	const classes = useStyles();
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [openD, setOpenD] = React.useState(false);
	const [currentPage, setCurrentPage] = React.useState("ContentDash");

	const [selectedIndex, setSelectedIndex] = React.useState("");

	// const handleClick = (index) => {
	//   if (selectedIndex === index) {
	//     setSelectedIndex("");
	//   } else {
	//     setSelectedIndex(index);
	//   }
	// };

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};
	// const dashIconClicked = (text) => {
	//   setOpen(false);
	//   console.log("dash icon clicked", text);
	//   setCurrentPage(text);
	// };

	// const handleClick = () => {
	//   setOpen(!open);
	// };

	const logoutButtonClicked = () => {
		setOpenD(true);
	};
	const closePopup = () => {
		setOpenD(false);
	};

	const logout = () => {
		firebase
			.auth()
			.signOut()
			.then(function () {
				console.log("Sign-out successful.");
				props.history.push("/login");
				localStorage.clear();
			})
			.catch(function (error) {
				console.log("An error happened while signing out");
			});
	};

	const isLogged = !!localStorage.getItem("userlogged");
	if (!isLogged) {
		return <Redirect to="/login" />;
	}
	return (
		<div style={{ display: "flex" }}>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(classes.menuButton, open && classes.hide)}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" noWrap>
						SHIFU Content Dashboard
					</Typography>
					<Dialog aria-labelledby="form-dialog-title" open={openD}>
						<DialogTitle style={{ marginTop: "2%" }} id="form-dialog-title">
							Logout{" "}
						</DialogTitle>
						<DialogContent>
							<DialogContentText>Are you sure you want to Logout?</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button color="primary" onClick={logout}>
								Logout
							</Button>
							<Button color="primary" onClick={closePopup}>
								Close
							</Button>
						</DialogActions>
					</Dialog>
					{/* <Tooltip title="History">
            <IconButton style={{ marginLeft: "auto", marginRight: "10px" }}>
              <HistoryIcon
                tooltip="History"
                type="submit"
                variant="contained"
                fontSize="large"
                style={{ color: "white" }}
                // onClick={(event) => (window.location.href = "/history")}
              />
            </IconButton>
          </Tooltip> */}

					<Button
						type="submit"
						variant="contained"
						color="default"
						onClick={logoutButtonClicked}
						style={{ marginLeft: "auto", marginRight: "10px" }}
					>
						Logout
					</Button>
				</Toolbar>
			</AppBar>
			<Drawer
				className={classes.drawer}
				variant="persistent"
				anchor="left"
				open={open}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<div className={classes.drawerHeader}>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
					</IconButton>
				</div>
				<Divider />
				<MultiMenus menus={menus} props={props} />
			</Drawer>
			<main
				className={clsx(classes.content, {
					[classes.contentShift]: open,
				})}
			>
				<div className={classes.drawerHeader} />
				{currentPage === "ContentDash" ? <Dashboard /> : <Dashboard />}
			</main>
		</div>
	);
}

export default withRouter(Sidebar);
