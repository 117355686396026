import React, { Component } from "react";
import HistoryTable from "./Components/HistoryTable";
export default class Dashboard extends Component {
  render() {
    return (
      <div style={{ padding: "2rem" }}>
        <HistoryTable />
      </div>
    );
  }
}
