import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import DataTable from "./Components/DataTable";
import DatatableWeb from "./Components/DatatableWeb";
class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentType: "app",
		};
	}
	// componentDidMount() {
	// 	let url_array = this.props.history.location.pathname.split("/");
	// 	url_array = url_array.filter((el) => {
	// 		return el != "";
	// 	});

	// 	if (url_array[0] == "app") {
	// 		this.setState({ contentType: "app" });
	// 	} else {
	// 		this.setState({ contentType: "web" });
	// 	}
	// }
	render() {
		const isLogged = !!localStorage.getItem("userlogged");
		if (!isLogged) {
			return <Redirect to="/login" />;
		}
		let url_array = this.props.history.location.pathname.split("/");
		url_array = url_array.filter((el) => {
			return el != "";
		});
		// console.log("url_array ---", url_array[0]);
		if (url_array[0] == "app" && this.state.contentType != "app") {
			this.setState({ contentType: "app" });
		} else if (url_array[0] == "web" && this.state.contentType != "web") {
			this.setState({ contentType: "web" });
		}
		return (
			<div style={{ padding: "2rem", width: "100%" }}>
				{this.state.contentType == "app" ? (
					<DataTable />
				) : (
					<DatatableWeb />
					// <DataTable />
				)}
			</div>
		);
	}
}

export default connect(null, null)(withRouter(Dashboard));
