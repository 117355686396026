export const languages = {
	English: {
		active_on_plugo: true,
		active_on_tacto: true,
	},
	Japanese: {
		active_on_plugo: true,
		active_on_tacto: true,
	},
	French: {
		active_on_plugo: true,
	},
	German: {
		active_on_plugo: true,
	},
	Korean: {
		active_on_plugo: true,
		active_on_tacto: true,
	},
	Chinese: {
		active_on_plugo: true,
	},
	Polish: {
		active_on_plugo: true,
	},
	Dutch: {
		active_on_plugo: true,
	},
	Swedish: {
		active_on_plugo: true,
		active_on_tacto: true,
	},
	Danish: {
		active_on_plugo: true,
		active_on_tacto: true,
	},
	Norwegian: {
		active_on_plugo: true,
		active_on_tacto: true,
	},
	ChineseTraditional: {
		active_on_plugo: true,
	},
	Russian: {
		active_on_plugo: true,
		active_on_tacto: true,
	},
	Czech: {
		active_on_plugo: true,
	},
	Spanish: {
		active_on_plugo: true,
		active_on_tacto: true,
	},
	Slovenian: {
		active_on_plugo: true,
		active_on_tacto: true,
	},
	PortugueseBrazil: {
		active_on_plugo: true,
		active_on_tacto: true,
	},
	Italian: {
		active_on_plugo: true,
		active_on_tacto: true,
	},
	Estonian: {
		active_on_tacto: true,
	},
	Latvian: {
		active_on_tacto: true,
	},
	Lithuanian: {
		active_on_tacto: true,
	},
};
