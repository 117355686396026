import {
	GET_WEB_ORDERS,
	//  GET_GA_DATA
} from "./types";
import { firebase } from "../firebase";
import { languages } from "../static_json/languages";
// const _ = require("lodash");
// const moment = require("moment-timezone");

const db = firebase.firestore();

const kits_mapping = [
	"new_keys", //0
	"generic",
	"count_common",
	"theo_common",
	"theo",
	"numero_common", //5
	"numerov2",
	"space",
	"dana",
	"mcc",
	"mccv2", //10
	"cm1",
	"cm2",
	"cm3",
	"link_common",
	"gears", //15
	"wordhunt",
	"memory",
	"frog",
	"physics_prototype",
	"pipes", //20
	"piano_common",
	"tory",
	"alphabeto_common",
	"cat",
	"fish", //25
	"dino",
	"puzzle",
	"oko",
	"oko2",
	"caveman", //30
	"crossword",
	"lettersDetective",
	"lm1",
	"lm2",
	"lm3", //35
	"catapult_common",
	"iggy",
	"tizi",
	"waterfall",
	"animals_common", //40
	"eli",
	"vaaria",
	"animals_lm",
	"detective_common",
	"pdet1", //45
	"pdet2",
	"pdet3",
	"farm_common",
	"happy_barn",
	"farm_lm_otis", //50
	"farm_lm2",
];

const tacto_keys_mapping = [
	"new_keys_tacto",
	"Common",
	"Retro_Common",
	"Retro_Ludo",
	"Retro_TrollsAndLadders", //61
	"Retro_Checkers",
	"Retro_TicTacToe",
	"Laser_Common",
	"Laser_SanaAndPuppy",
	"Laser_Detective", //66
	"Laser_MazeProd",
	"Laser_LaserSlime",
	"Chess_Common",
	"Chess_ChessGame",
	"Coding_Common", //71
	"Coding_CodingAnimals",
	"Coding_CodingGameTwoProto",
	"Coding_CodeOnTheRun",
	"Coding_CodingGameFour",
	"Electronics_Common", //76
	"Electronics_ElectronicsG",
	"Electronics_EGTwo", //78
	"Electronics_ElectronicsGameThree",
	"Electronics_ElectronicsLearnMode",
	"Dinos_Common",
	"Dinos_TactoDino", //82
	"Doctor_Common", //85
	"Doctor_DoctorOne",
	"Doctor_DoctorTwo",
	"Doctor_DoctorThree", //88
];

export const getWebOrders = (dispatch, game) => {
	// console.log("INDEX GET WEB ORDERS", index);
	let array_of_rows = [];

	let query = db.collection("content-translation");

	console.log("Get data = ", game);
	const chunkSize = 10;
	if (game) {
		// if (game == "new_keys") {
		// 	for (let i = 0; i < kits_mapping.length; i += chunkSize) {
		// 		const chunk = kits_mapping.slice(i, i + chunkSize);

		// 		query = query.where("game", "in", chunk);
		// 	}
		// } else if (game == "new_keys_tacto") {
		// 	for (let i = 0; i < tacto_keys_mapping.length; i += chunkSize) {
		// 		const chunk = tacto_keys_mapping.slice(i, i + chunkSize);

		// 		query = query.where("game", "in", chunk);
		// 	}
		// }
		if (!game.includes("new_keys")) {
			query = query.where("game", "==", game);
		}
		// query = query.where("Key", "==", "gears_v2_Success_chameleon_head");

		query = query.orderBy("lowercaseKey");

		// query.get().then((doc) => {
		// 	console.log(doc);
		// });
		// return;
		// const querySnapshot = query.get();
		query.get().then((querySnapshot) => {
			console.log("On snapshot==================");
			querySnapshot.forEach((doc) => {
				Object.keys(doc.data()).sort();
				let document = doc.data();

				let is_new_key = false;
				let is_new_key_tacto = false;
				let contentToBeTranslated = [];
				// console.log("each row =" + doc.id + "-----");

				if (kits_mapping.indexOf(document.game) > -1) {
					// check if the key is new
					Object.keys(languages)
						.filter((lang) => languages[lang].active_on_plugo === true)
						.map((lang) => {
							if (
								!document[lang] ||
								document[lang] === "" ||
								document[lang] === null ||
								document[lang].includes(true)
							) {
								is_new_key = "plugo";
								contentToBeTranslated.push(lang);
							}
						});
					if (document.IsAudio === "" || document.IsAudio === null) {
						is_new_key = "plugo";
					}
					// add language here
					// if (
					// 	document.English === "" ||
					// 	document.English === null ||
					// 	document.Chinese === "" ||
					// 	document.Chinese === null ||
					// 	document.Chinese === undefined ||
					// 	document.Chinese.includes(true) ||
					// 	document.Dutch === "" ||
					// 	document.Dutch === null ||
					// 	document.Dutch === undefined ||
					// 	document.Dutch.includes(true) ||
					// 	document.French === "" ||
					// 	document.French === null ||
					// 	document.French === undefined ||
					// 	document.French.includes(true) ||
					// 	document.German === "" ||
					// 	document.German === null ||
					// 	document.German === undefined ||
					// 	document.German.includes(true) ||
					// 	document.Japanese === "" ||
					// 	document.Japanese === null ||
					// 	document.Japanese === undefined ||
					// 	document.Japanese.includes(true) ||
					// 	document.Korean === "" ||
					// 	document.Korean === null ||
					// 	document.Korean === undefined ||
					// 	document.Korean.includes(true) ||
					// 	document.Polish === "" ||
					// 	document.Polish === null ||
					// 	document.Polish === undefined ||
					// 	document.Polish.includes(true) ||
					// 	document.Swedish === "" ||
					// 	document.Swedish === null ||
					// 	document.Swedish === undefined ||
					// 	document.Swedish.includes(true) ||
					// 	document.Danish === "" ||
					// 	document.Danish === null ||
					// 	document.Danish === undefined ||
					// 	document.Danish.includes(true) ||
					// 	document.Russian === "" ||
					// 	document.Russian === null ||
					// 	document.Russian === undefined ||
					// 	document.Russian.includes(true) ||
					// 	document.Norwegian === "" ||
					// 	document.Norwegian === null ||
					// 	document.Norwegian === undefined ||
					// 	document.Norwegian.includes(true) ||
					// 	document.Czech === "" ||
					// 	document.Czech === null ||
					// 	document.Czech === undefined ||
					// 	document.Czech.includes(true) ||
					// 	document.ChineseTraditional === "" ||
					// 	document.ChineseTraditional === null ||
					// 	document.ChineseTraditional === undefined ||
					// 	document.ChineseTraditional.includes(true) ||
					// 	document.Spanish === "" ||
					// 	document.Spanish === null ||
					// 	document.Spanish === undefined ||
					// 	document.Spanish.includes(true) ||
					// 	document.Slovenian === "" ||
					// 	document.Slovenian === null ||
					// 	document.Slovenian === undefined ||
					// 	document.Slovenian.includes(true) ||
					// 	document.IsAudio === "" ||
					// 	document.IsAudio === null
					// ) {
					// 	is_new_key = true;
					// }

					// if (
					// 	document.Chinese === "" ||
					// 	document.Chinese === null ||
					// 	(typeof document.Chinese === "object" && document.Chinese[1] === true)
					// )
					// 	contentToBeTranslated.push("Chinese");
					// // is_new_key = true
					// if (
					// 	document.Dutch === "" ||
					// 	document.Dutch === null ||
					// 	(typeof document.Dutch === "object" && document.Dutch[1] === true)
					// )
					// 	contentToBeTranslated.push("Dutch");
					// // is_new_key = true
					// if (
					// 	document.French === "" ||
					// 	document.French === null ||
					// 	(typeof document.French === "object" && document.French[1] === true)
					// )
					// 	contentToBeTranslated.push("French");
					// // is_new_key = true
					// if (
					// 	document.German === "" ||
					// 	document.German === null ||
					// 	(typeof document.German === "object" && document.German[1] === true)
					// )
					// 	contentToBeTranslated.push("German");
					// // is_new_key = true
					// if (
					// 	document.Japanese === "" ||
					// 	document.Japanese === null ||
					// 	(typeof document.Japanese === "object" && document.Japanese[1] === true)
					// )
					// 	contentToBeTranslated.push("Japanese");
					// // is_new_key = true
					// if (
					// 	document.Korean === "" ||
					// 	document.Korean === null ||
					// 	(typeof document.Korean === "object" && document.Korean[1] === true)
					// )
					// 	contentToBeTranslated.push("Korean");
					// // is_new_key = true
					// if (
					// 	document.Polish === "" ||
					// 	document.Polish === null ||
					// 	(typeof document.Polish === "object" && document.Polish[1] === true)
					// )
					// 	contentToBeTranslated.push("Polish");
					// // is_new_key = true
					// if (
					// 	document.Swedish === "" ||
					// 	document.Swedish === null ||
					// 	(typeof document.Swedish === "object" && document.Swedish[1] === true)
					// )
					// 	contentToBeTranslated.push("Swedish");
					// if (
					// 	document.Danish === "" ||
					// 	document.Danish === null ||
					// 	(typeof document.Danish === "object" && document.Danish[1] === true)
					// )
					// 	contentToBeTranslated.push("Danish");
					// if (
					// 	document.Russian === "" ||
					// 	document.Russian === null ||
					// 	(typeof document.Russian === "object" && document.Russian[1] === true)
					// )
					// 	contentToBeTranslated.push("Russian");
					// if (
					// 	document.Norwegian === "" ||
					// 	document.Norwegian === null ||
					// 	(typeof document.Norwegian === "object" && document.Norwegian[1] === true)
					// )
					// 	contentToBeTranslated.push("Norwegian");
					// if (
					// 	document.Czech === "" ||
					// 	document.Czech === null ||
					// 	(typeof document.Czech === "object" && document.Czech[1] === true)
					// )
					// 	contentToBeTranslated.push("Czech");
					// if (
					// 	document.ChineseTraditional === "" ||
					// 	document.ChineseTraditional === null ||
					// 	(typeof document.ChineseTraditional === "object" &&
					// 		document.ChineseTraditional[1] === true)
					// )
					// 	contentToBeTranslated.push("ChineseTraditional");
					// if (
					// 	document.Spanish === "" ||
					// 	document.Spanish === null ||
					// 	(typeof document.Spanish === "object" && document.Spanish[1] === true)
					// )
					// 	contentToBeTranslated.push("Spanish");
					// if (
					// 	document.Slovenian === "" ||
					// 	document.Slovenian === null ||
					// 	(typeof document.Slovenian === "object" && document.Slovenian[1] === true)
					// )
					// 	contentToBeTranslated.push("Slovenian");
					// if (
					// 	document.PortugueseBrazil === "" ||
					// 	document.PortugueseBrazil === null ||
					// 	(typeof document.PortugueseBrazil === "object" &&
					// 		document.PortugueseBrazil[1] === true)
					// )
					// 	contentToBeTranslated.push("PortugueseBrazil");
					// if (
					// 	document.Italian === "" ||
					// 	document.Italian === null ||
					// 	(typeof document.Italian === "object" && document.Italian[1] === true)
					// )
					// 	contentToBeTranslated.push("Italian");
					// if (
					// 	document.Estonian === "" ||
					// 	document.Estonian === null ||
					// 	(typeof document.Estonian === "object" && document.Estonian[1] === true)
					// )
					// 	contentToBeTranslated.push("Estonian");
					// if (
					// 	document.Latvian === "" ||
					// 	document.Latvian === null ||
					// 	(typeof document.Latvian === "object" && document.Latvian[1] === true)
					// )
					// 	contentToBeTranslated.push("Latvian");
					// if (
					// 	document.Lithuanian === "" ||
					// 	document.Lithuanian === null ||
					// 	(typeof document.Lithuanian === "object" && document.Lithuanian[1] === true)
					// )
					// 	contentToBeTranslated.push("Lithuanian");
					// is_new_key = true

					let hasNoAudio = [];
					if (document.IsAudio === "Text") {
						hasNoAudio.push("NA");
					} else {
						if (typeof document.English === "string") {
							hasNoAudio.push("English");
						}
						if (typeof document.Chinese === "object") {
							let checkAudio = document.Chinese.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Chinese");
						}
						if (typeof document.Chinese === "string") {
							hasNoAudio.push("Chinese");
						}
						if (typeof document.Dutch === "object") {
							let checkAudio = document.Dutch.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Dutch");
						}
						if (typeof document.Dutch === "string") {
							hasNoAudio.push("Dutch");
						}
						if (typeof document.French === "object") {
							let checkAudio = document.French.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("French");
						}
						if (typeof document.French === "string") {
							hasNoAudio.push("French");
						}
						if (typeof document.German === "object") {
							let checkAudio = document.German.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("German");
						}
						if (typeof document.German === "string") {
							hasNoAudio.push("German");
						}
						if (typeof document.Japanese === "object") {
							let checkAudio = document.Japanese.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Japanese");
						}
						if (typeof document.Japanese === "string") {
							hasNoAudio.push("Japanese");
						}
						if (typeof document.Korean === "object") {
							let checkAudio = document.Korean.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Korean");
						}
						if (typeof document.Korean === "string") {
							hasNoAudio.push("Korean");
						}
						if (typeof document.Polish === "object") {
							let checkAudio = document.Polish.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Polish");
						}
						if (typeof document.Polish === "string") {
							hasNoAudio.push("Polish");
						}
						if (typeof document.Swedish === "object") {
							let checkAudio = document.Swedish.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Swedish");
						}
						if (typeof document.Swedish === "string") {
							hasNoAudio.push("Swedish");
						}
						if (typeof document.Danish === "object") {
							let checkAudio = document.Danish.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Danish");
						}
						if (typeof document.Danish === "string") {
							hasNoAudio.push("Danish");
						}
						if (typeof document.Russian === "object") {
							let checkAudio = document.Russian.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Russian");
						}
						if (typeof document.Russian === "string") {
							hasNoAudio.push("Russian");
						}
						if (typeof document.Norwegian === "object") {
							let checkAudio = document.Norwegian.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Norwegian");
						}
						if (typeof document.Norwegian === "string") {
							hasNoAudio.push("Norwegian");
						}
						if (typeof document.Czech === "object") {
							let checkAudio = document.Czech.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Czech");
						}
						if (typeof document.Czech === "string") {
							hasNoAudio.push("Czech");
						}
						if (typeof document.ChineseTraditional === "object") {
							let checkAudio = document.ChineseTraditional.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("ChineseTraditional");
						}
						if (typeof document.ChineseTraditional === "string") {
							hasNoAudio.push("ChineseTraditional");
						}
						if (typeof document.Spanish === "object") {
							let checkAudio = document.Spanish.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Spanish");
						}
						if (typeof document.Spanish === "string") {
							hasNoAudio.push("Spanish");
						}
						if (typeof document.Slovenian === "object") {
							let checkAudio = document.Slovenian.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Slovenian");
						}
						if (typeof document.Slovenian === "string") {
							hasNoAudio.push("Slovenian");
						}
						if (typeof document.PortugueseBrazil === "object") {
							let checkAudio = document.PortugueseBrazil.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("PortugueseBrazil");
						}
						if (typeof document.PortugueseBrazil === "string") {
							hasNoAudio.push("PortugueseBrazil");
						}
						if (typeof document.Italian === "object") {
							let checkAudio = document.Italian.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Italian");
						}
						if (typeof document.Italian === "string") {
							hasNoAudio.push("Italian");
						}
						// if (typeof document.Estonian === "object") {
						// 	let checkAudio = document.Estonian.find((v) =>
						// 		typeof v === "string" ? v.includes("ogg") : null
						// 	);
						// 	if (checkAudio === undefined) hasNoAudio.push("Estonian");
						// }
						// if (typeof document.Estonian === "string") {
						// 	hasNoAudio.push("Estonian");
						// }
						// if (typeof document.Latvian === "object") {
						// 	let checkAudio = document.Latvian.find((v) =>
						// 		typeof v === "string" ? v.includes("ogg") : null
						// 	);
						// 	if (checkAudio === undefined) hasNoAudio.push("Latvian");
						// }
						// if (typeof document.Latvian === "string") {
						// 	hasNoAudio.push("Latvian");
						// }
						// if (typeof document.Lithuanian === "object") {
						// 	let checkAudio = document.Lithuanian.find((v) =>
						// 		typeof v === "string" ? v.includes("ogg") : null
						// 	);
						// 	if (checkAudio === undefined) hasNoAudio.push("Lithuanian");
						// }
						// if (typeof document.Lithuanian === "string") {
						// 	hasNoAudio.push("Lithuanian");
						// }
					}

					// add language here
					let translations_array = [
						document.Key, //0
						document.English,
						document.Japanese,
						document.French,
						document.German,
						document.Korean,
						document.Chinese,
						document.Polish,
						document.Dutch,
						document.Swedish,
						document.Danish, //10
						document.Norwegian,
						document.ChineseTraditional,
						document.Russian,
						document.Czech,
						document.Spanish,
						document.Slovenian, //16
						document.PortugueseBrazil, //17
						document.Italian, //18
						document.Estonian, //19
						document.Latvian, //20
						document.Lithuanian, //21
						document.IsAudio, //22
						document.game, //23
						is_new_key, //24
						document.createdAt, //25
						contentToBeTranslated, //26
						document.voiceData ? document.voiceData : null, //27
						hasNoAudio, //28
					];
					if (translations_array[0]) array_of_rows.push(translations_array);
				} else if (tacto_keys_mapping.indexOf(document.game) > -1) {
					let contentToBeTranslated = [];
					Object.keys(languages)
						.filter((lang) => languages[lang].active_on_tacto === true)
						.map((lang) => {
							if (
								!document[lang] ||
								document[lang] === "" ||
								document[lang] === null ||
								document[lang].includes(true)
							) {
								is_new_key_tacto = "tacto";
							}
							if (
								!document[lang] ||
								document[lang] === "" ||
								document[lang] === null ||
								(typeof document[lang] === "object" && document[lang][1] === true)
							)
								contentToBeTranslated.push(lang);
						});
					// if (
					// 	document.English === "" ||
					// 	document.English === null ||
					// 	document.English.includes(true) ||
					// 	document.Japanese === "" ||
					// 	document.Japanese === null ||
					// 	document.Japanese.includes(true) ||
					// 	document.Russian === "" ||
					// 	document.Russian === null ||
					// 	document.Russian.includes(true) ||
					// 	document.Danish === "" ||
					// 	document.Danish === null ||
					// 	document.Danish.includes(true) ||
					// 	document.Swedish === "" ||
					// 	document.Swedish === null ||
					// 	document.Swedish.includes(true) ||
					// 	document.Spanish === "" ||
					// 	document.Spanish === null ||
					// 	document.Spanish.includes(true) ||
					// 	document.Slovenian === "" ||
					// 	document.Slovenian === null ||
					// 	document.Slovenian.includes(true) ||
					// 	document.PortugueseBrazil === "" ||
					// 	document.PortugueseBrazil === null ||
					// 	document.PortugueseBrazil.includes(true) ||
					// 	document.Italian === "" ||
					// 	document.Italian === null ||
					// 	document.Italian.includes(true) ||
					// 	document.Estonian === "" ||
					// 	document.Estonian === null ||
					// 	document.Estonian.includes(true) ||
					// 	document.Latvian === "" ||
					// 	document.Latvian === null ||
					// 	document.Latvian.includes(true) ||
					// 	document.Lithuanian === "" ||
					// 	document.Lithuanian === null ||
					// 	document.Lithuanian.includes(true) ||
					// 	document.Korean === "" ||
					// 	document.Korean === null ||
					// 	document.Korean.includes(true) ||
					// 	document.Norwegian === "" ||
					// 	document.Norwegian === null ||
					// 	document.Norwegian.includes(true) ||
					// 	document.IsAudio === "" ||
					// 	document.IsAudio === null
					// ) {
					// 	is_new_key_tacto = true;
					// }

					// if (document.Chinese === "" || document.Chinese === null || (typeof(document.Chinese) === "object" && document.Chinese[1] === true) )
					//   contentToBeTranslated.push("Chinese");
					// if (document.Dutch === "" || document.Dutch === null || (typeof(document.Dutch) === "object" && document.Dutch[1] === true))
					//   contentToBeTranslated.push("Dutch");
					// if (document.French === "" || document.French === null || (typeof(document.French) === "object" && document.French[1] === true))
					//   contentToBeTranslated.push("French");
					// if (document.German === "" || document.German === null || (typeof(document.German) === "object" && document.German[1] === true))
					//   contentToBeTranslated.push("German");

					//new commented
					// if (
					// 	document.Japanese === "" ||
					// 	document.Japanese === null ||
					// 	(typeof document.Japanese === "object" && document.Japanese[1] === true)
					// ) {
					// 	contentToBeTranslated.push("Japanese");
					// }
					// if (
					// 	document.Russian === "" ||
					// 	document.Russian === null ||
					// 	(typeof document.Russian === "object" && document.Russian[1] === true)
					// ) {
					// 	contentToBeTranslated.push("Russian");
					// }
					// if (
					// 	document.Korean === "" ||
					// 	document.Korean === null ||
					// 	(typeof document.Korean === "object" && document.Korean[1] === true)
					// ) {
					// 	contentToBeTranslated.push("Korean");
					// }
					// if (
					// 	document.Norwegian === "" ||
					// 	document.Norwegian === null ||
					// 	(typeof document.Norwegian === "object" && document.Norwegian[1] === true)
					// ) {
					// 	contentToBeTranslated.push("Norwegian");
					// }
					// if (
					// 	document.Danish === "" ||
					// 	document.Danish === null ||
					// 	(typeof document.Danish === "object" && document.Danish[1] === true)
					// )
					// 	contentToBeTranslated.push("Danish");
					// if (
					// 	document.Swedish === "" ||
					// 	document.Swedish === null ||
					// 	(typeof document.Swedish === "object" && document.Swedish[1] === true)
					// )
					// 	contentToBeTranslated.push("Swedish");
					// if (
					// 	document.Spanish === "" ||
					// 	document.Spanish === null ||
					// 	(typeof document.Spanish === "object" && document.Spanish[1] === true)
					// )
					// 	contentToBeTranslated.push("Spanish");
					// if (
					// 	document.Slovenian === "" ||
					// 	document.Slovenian === null ||
					// 	(typeof document.Slovenian === "object" && document.Slovenian[1] === true)
					// )
					// 	contentToBeTranslated.push("Slovenian");
					// if (
					// 	document.PortugueseBrazil === "" ||
					// 	document.PortugueseBrazil === null ||
					// 	(typeof document.PortugueseBrazil === "object" &&
					// 		document.PortugueseBrazil[1] === true)
					// )
					// 	contentToBeTranslated.push("PortugueseBrazil");
					// if (
					// 	document.Italian === "" ||
					// 	document.Italian === null ||
					// 	(typeof document.Italian === "object" && document.Italian[1] === true)
					// )
					// 	contentToBeTranslated.push("Italian");
					// if (
					// 	document.Estonian === "" ||
					// 	document.Estonian === null ||
					// 	(typeof document.Estonian === "object" && document.Estonian[1] === true)
					// )
					// 	contentToBeTranslated.push("Estonian");
					// if (
					// 	document.Latvian === "" ||
					// 	document.Latvian === null ||
					// 	(typeof document.Latvian === "object" && document.Latvian[1] === true)
					// )
					// 	contentToBeTranslated.push("Latvian");
					// if (
					// 	document.Lithuanian === "" ||
					// 	document.Lithuanian === null ||
					// 	(typeof document.Lithuanian === "object" && document.Lithuanian[1] === true)
					// )
					// 	contentToBeTranslated.push("Lithuanian");
					//new commented

					// if (document.Korean === "" || document.Korean === null || (typeof(document.Korean) === "object" && document.Korean[1] === true))
					//   contentToBeTranslated.push("Korean");
					// if (document.Polish === "" || document.Polish === null || (typeof(document.Polish) === "object" && document.Polish[1] === true))
					//   contentToBeTranslated.push("Polish");

					let hasNoAudio = [];
					if (document.IsAudio === "Text") {
						hasNoAudio.push("NA");
					} else {
						if (typeof document.English === "string") {
							hasNoAudio.push("English");
						}
						if (typeof document.Japanese === "object") {
							let checkAudio = document.Japanese.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Japanese");
						}
						if (typeof document.Japanese === "string") {
							hasNoAudio.push("Japanese");
						}
						if (typeof document.Korean === "object") {
							let checkAudio = document.Korean.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Korean");
						}
						if (typeof document.Korean === "string") {
							hasNoAudio.push("Korean");
						}
						if (typeof document.Polish === "object") {
							let checkAudio = document.Polish.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Polish");
						}
						if (typeof document.Polish === "string") {
							hasNoAudio.push("Polish");
						}
						if (typeof document.Swedish === "object") {
							let checkAudio = document.Swedish.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Swedish");
						}
						if (typeof document.Swedish === "string") {
							hasNoAudio.push("Swedish");
						}
						if (typeof document.Danish === "object") {
							let checkAudio = document.Danish.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Danish");
						}
						if (typeof document.Danish === "string") {
							hasNoAudio.push("Danish");
						}
						if (typeof document.Russian === "object") {
							let checkAudio = document.Russian.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Russian");
						}
						if (typeof document.Russian === "string") {
							hasNoAudio.push("Russian");
						}
						if (typeof document.Norwegian === "object") {
							let checkAudio = document.Norwegian.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Norwegian");
						}
						if (typeof document.Norwegian === "string") {
							hasNoAudio.push("Norwegian");
						}
						if (typeof document.Spanish === "object") {
							let checkAudio = document.Spanish.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Spanish");
						}
						if (typeof document.Spanish === "string") {
							hasNoAudio.push("Spanish");
						}
						if (typeof document.Slovenian === "object") {
							let checkAudio = document.Slovenian.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Slovenian");
						}
						if (typeof document.Slovenian === "string") {
							hasNoAudio.push("Slovenian");
						}
						if (typeof document.PortugueseBrazil === "object") {
							let checkAudio = document.PortugueseBrazil.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("PortugueseBrazil");
						}
						if (typeof document.PortugueseBrazil === "string") {
							hasNoAudio.push("PortugueseBrazil");
						}
						if (typeof document.Italian === "object") {
							let checkAudio = document.Italian.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Italian");
						}
						if (typeof document.Italian === "string") {
							hasNoAudio.push("Italian");
						}
						if (typeof document.Estonian === "object") {
							let checkAudio = document.Estonian.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Estonian");
						}
						if (typeof document.Estonian === "string") {
							hasNoAudio.push("Estonian");
						}
						if (typeof document.Latvian === "object") {
							let checkAudio = document.Latvian.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Latvian");
						}
						if (typeof document.Latvian === "string") {
							hasNoAudio.push("Latvian");
						}
						if (typeof document.Lithuanian === "object") {
							let checkAudio = document.Lithuanian.find((v) =>
								typeof v === "string" ? v.includes("ogg") : null
							);
							if (checkAudio === undefined) hasNoAudio.push("Lithuanian");
						}
						if (typeof document.Lithuanian === "string") {
							hasNoAudio.push("Lithuanian");
						}
					}

					// add language here
					let translations_array = [
						document.Key,
						document.English,
						document.Japanese,
						document.French,
						document.German,
						document.Korean,
						document.Chinese,
						document.Polish,
						document.Dutch,
						document.Swedish,
						document.Danish,
						document.Norwegian,
						document.ChineseTraditional,
						document.Russian,
						document.Czech,
						document.Spanish,
						document.Slovenian,
						document.PortugueseBrazil, //17
						document.Italian, //18
						document.Estonian, //19
						document.Latvian, //20
						document.Lithuanian, //21
						document.IsAudio,
						document.game,
						is_new_key_tacto,
						document.createdAt,
						contentToBeTranslated,
						document.voiceData ? document.voiceData : null,
						hasNoAudio,
					];
					if (translations_array[0]) array_of_rows.push(translations_array);
				}
			});

			dispatch({
				type: GET_WEB_ORDERS,
				payload: array_of_rows,
			});
		});
	}
};
