import { GET_WEB_ORDERS, GET_GA_DATA, DELETE_ITEM, ADD_ITEM, EDIT_ITEM } from "./types";
import moment from "moment";
import { getWebOrders } from "../actions/getDataAction";
import { firebase } from "../firebase";
import store from "../store";
import { indexOf } from "lodash";
import { languages } from "../static_json/languages";
const _ = require("lodash");
// const moment = require("moment-timezone");
const db = firebase.firestore();
const fieldValue = firebase.firestore.FieldValue;

export const cancelOrder = (dispatch, orderId) => {};
export const cancelItem = (dispatch, sku, orderId) => {};
export const updateUserDetails = (dispatch, data) => {
	console.log(data);
	db.collection("content-translation")
		.doc(data.Key + "_" + data.game)
		.set(data);

	setTimeout(() => {
		window.location.reload();
	}, 2000);
};
export const deleteUserDetails = async (dispatch, data, e) => {
	console.log(data);
	await db
		.collection("content-translation")
		.doc(data.Key + "_" + data.game)
		.delete();

	setTimeout(() => {
		window.location.reload();
	}, 1000);

	// setTimeout(() => {
	//   let index = "";
	//   store.getState().webOrders.contentData.forEach((el) => {
	//     if (el[0] === data.Key) {
	//       index = indexOf(store.getState().webOrders.contentData, el);
	//     }
	//   });

	//   dispatch({
	//     type: DELETE_ITEM,
	//     payload: index,
	//   });
	// }, 1000);
};
export const updateShipmentLink = (dispatch, object) => {
	console.log(object);
	let searchdocId = object[2] + "_" + object[4];
	if (object[5] < 57) {
		console.log("DocId to be updated =" + searchdocId + "---------");
		let obj = {};
		if (object[1] === "Content Type") {
			object[1] = "IsAudio";
		}
		if (object[1] === "Game") {
			object[1] = object[1].toLowerCase();
		}
		obj[`${object[1]}`] = object[0];
		console.log("OBJ", obj);

		if (object[1] === "Key") {
			db.collection("content-translation")
				.doc(searchdocId)
				.get()
				.then((doc) => {
					console.log(doc.data(), "DATA");
					let game = doc.data().game;
					obj.game = game;
					obj.IsAudio = doc.data().IsAudio;
					obj.Key = object[0];
					obj.lowercaseKey = object[0].toLowerCase();
					obj.createdAt = moment.unix(Date.now() / 1000).format("LLLL");

					Object.keys(languages).forEach((lang) => {
						if (doc.data()[lang]) {
							obj[lang] = doc.data()[lang];
						} else {
							obj[lang] = "";
						}
					});

					// obj.English = doc.data().English;
					// obj.French = doc.data().French;
					// obj.Chinese = doc.data().Chinese;
					// obj.Dutch = doc.data().Dutch;
					// obj.Polish = doc.data().Polish;
					// obj.German = doc.data().German;
					// obj.Japanese = doc.data().Japanese;
					// obj.Korean = doc.data().Korean;
					// obj.Swedish = doc.data().Swedish;
					// obj.Danish = doc.data().Danish;
					// obj.Russian = doc.data().Russian;
					// obj.Norwegian = doc.data().Norwegian;
					// obj.Czech = doc.data().Czech;
					// obj.ChineseTraditional = doc.data().ChineseTraditional;
					// obj.Spanish = doc.data().Spanish;
					// obj.Slovenian = doc.data().Slovenian;
					// obj.PortugueseBrazil = doc.data().PortugueseBrazil;
					// obj.Italian = doc.data().Italian;
					// obj.Estonian = doc.data().Estonian;
					// obj.Latvian = doc.data().Latvian;
					// obj.Lithuanian = doc.data().Lithuanian;

					// delete old doc
					db.collection("content-translation")
						.doc(searchdocId)
						.delete()
						.then((_) => {
							// add new doc
							db.collection("content-translation")
								.doc(object[0] + "_" + object[4])
								.set(obj)
								.then((_) => {
									console.log("Updated on DB!");
									window.location.reload();
								});
						});
				});
		} else if (object[1] === "English") {
			console.log(searchdocId);
			db.collection("content-translation")
				.doc(searchdocId)
				.get()
				.then((doc) => {
					console.log(doc.data(), "DATA");
					let game = doc.data().game;
					let Key = doc.data().Key;
					obj.English = object[0];
					obj.Key = Key;
					obj.lowercaseKey = Key.toLowerCase();
					obj.IsAudio = doc.data().IsAudio;
					obj.createdAt = moment.unix(Date.now() / 1000).format("LLLL");
					obj.game = game;

					Object.keys(languages).forEach((lang) => {
						if (lang != "English") {
							obj[lang] = "";
						}
					});

					// obj.French = "";
					// obj.Chinese = "";
					// obj.German = "";
					// obj.Dutch = "";
					// obj.Polish = "";
					// obj.Danish = "";
					// obj.Russian = "";
					// obj.Norwegian = "";
					// obj.Czech = "";
					// obj.ChineseTraditional = "";
					// obj.Japanese = "";
					// obj.Korean = "";
					// obj.Swedish = "";
					// obj.Spanish = "";
					// obj.Slovenian = "";
					// obj.PortugueseBrazil = "";
					// obj.Italian = "";
					// obj.Estonian = "";
					// obj.Latvian = "";
					// obj.Lithuanian = "";

					// delete old doc
					db.collection("content-translation")
						.doc(searchdocId)
						.delete()
						.then((_) => {
							// add new doc
							console.log(obj);
							db.collection("content-translation")
								.doc(searchdocId)
								.set(obj)
								.then((_) => {
									console.log("Updated on DB!");
									window.location.reload();
								});
						});
				});
		} else if (object[1] === "Character's Name & Voice") {
			obj["voiceData"] = object[0];
			db.collection("content-translation")
				.doc(searchdocId)
				.update(obj)
				.then((_) => {
					console.log("Updated on DB!");
					window.location.reload();
				});
		} else {
			db.collection("content-translation")
				.doc(searchdocId)
				.get()
				.then((data) => {
					let doc_data = data.data()[object[1]];
					console.log(typeof doc_data, object[3]);
					if (typeof doc_data === "string" && object[3]) {
						obj[`${object[1]}`] = [object[0], object[3]];
						db.collection("content-translation")
							.doc(searchdocId)
							.update(obj)
							.then((_) => {
								console.log("Updated on DB!");
								window.location.reload();
							});
					} else if (typeof doc_data === "string" && object[3] === false) {
						db.collection("content-translation")
							.doc(searchdocId)
							.update(obj)
							.then((_) => {
								console.log("Updated on DB!");
								window.location.reload();
							});
					} else if (typeof doc_data === "object" && object[3]) {
						db.collection("content-translation")
							.doc(searchdocId)
							.update({ [object[1]]: fieldValue.arrayUnion(object[3]) })
							.then((_) => {
								console.log("Updated on DB!");
								window.location.reload();
							});
					} else if (
						typeof doc_data === "object" &&
						object[3] === false &&
						doc_data.length === 3
					) {
						console.log("3rd");
						db.collection("content-translation")
							.doc(searchdocId)
							.update({ [object[1]]: fieldValue.arrayRemove(true) })
							.then((_) => {
								console.log("Updated on DB!");
								window.location.reload();
							});
					} else if (
						typeof doc_data === "object" &&
						object[3] === false &&
						doc_data.length === 2
					) {
						obj[`${object[1]}`] = object[0];
						db.collection("content-translation")
							.doc(searchdocId)
							.update(obj)
							.then((_) => {
								console.log("Updated on DB!");
								window.location.reload();
							});
					} else {
						db.collection("content-translation")
							.doc(searchdocId)
							.update(obj)
							.then((_) => {
								console.log("Updated on DB!");
								window.location.reload();
							});
					}
				});
		}

		// setTimeout(() => {
		// 	window.location.reload();
		// }, 3000);
	} else {
		console.log("Doc ID to be updated = ", searchdocId);
		let obj = {};
		if (object[1] === "Content Type") {
			object[1] = "IsAudio";
		}
		if (object[1] === "Game") {
			object[1] = object[1].toLowerCase();
		}
		obj[`${object[1]}`] = object[0];
		console.log("OBJ", obj);

		if (object[1] === "Key") {
			db.collection("content-translation")
				.doc(searchdocId)
				.get()
				.then((doc) => {
					console.log(doc.data(), "DATA");
					let game = doc.data().game;
					obj.game = game;

					Object.keys(languages).forEach((lang) => {
						if (doc.data()[lang]) {
							obj[lang] = doc.data()[lang];
						} else {
							obj[lang] = "";
						}
					});

					// obj.English = doc.data().English;
					// obj.Japanese = doc.data().Japanese;
					// obj.Russian = doc.data().Russian;
					// obj.Danish = doc.data().Danish;
					// obj.Swedish = doc.data().Swedish;
					// obj.Spanish = doc.data().Spanish;
					// obj.Slovenian = doc.data().Slovenian;
					// obj.PortugueseBrazil = doc.data().PortugueseBrazil;
					// obj.Italian = doc.data().Italian;
					// obj.Estonian = doc.data().Estonian;
					// obj.Latvian = doc.data().Latvian;
					// obj.Lithuanian = doc.data().Lithuanian;

					// obj.Korean = doc.data().Korean;
					// obj.Norwegian = doc.data().Norwegian;
					obj.IsAudio = doc.data().IsAudio;
					obj.Key = object[0];
					obj.lowercaseKey = object[0].toLowerCase();
					obj.createdAt = moment.unix(Date.now() / 1000).format("LLLL");

					// delete old doc
					db.collection("content-translation")
						.doc(searchdocId)
						.delete()
						.then((_) => {
							// add new doc
							db.collection("content-translation")
								.doc(object[0] + "_" + object[4])
								.set(obj)
								.then((_) => {
									console.log("Updated on DB!");
									window.location.reload();
								});
						});
				});
		} else if (object[1] === "English") {
			console.log(searchdocId);
			db.collection("content-translation")
				.doc(searchdocId)
				.get()
				.then((doc) => {
					console.log(doc.data(), "DATA");
					let game = doc.data().game;
					let Key = doc.data().Key;
					obj.game = game;
					obj.English = object[0];
					obj.Key = Key;
					obj.lowercaseKey = Key.toLowerCase();
					obj.IsAudio = doc.data().IsAudio;
					obj.createdAt = moment.unix(Date.now() / 1000).format("LLLL");

					Object.keys(languages).forEach((lang) => {
						if (lang != "English") {
							obj[lang] = "";
						}
					});
					// obj.French = "";
					// obj.Chinese = "";
					// obj.German = "";
					// obj.Dutch = "";
					// obj.Polish = "";
					// obj.Danish = "";
					// obj.Russian = "";
					// obj.Norwegian = "";
					// obj.Czech = "";
					// obj.ChineseTraditional = "";
					// obj.Japanese = "";
					// obj.Korean = "";
					// obj.Swedish = "";
					// obj.Spanish = "";

					// delete old doc
					db.collection("content-translation")
						.doc(searchdocId)
						.delete()
						.then((_) => {
							// add new doc
							console.log(obj);
							db.collection("content-translation")
								.doc(searchdocId)
								.set(obj)
								.then((_) => {
									console.log("Updated on DB!");
									window.location.reload();
								});
						});
				});
		} else if (object[1] === "Character's Name & Voice") {
			obj["voiceData"] = object[0];
			db.collection("content-translation")
				.doc(searchdocId)
				.update(obj)
				.then((_) => {
					console.log("Updated on DB!");
					window.location.reload();
				});
		} else {
			let doc = db.collection("content-translation").doc(searchdocId).get();
			doc.then((data) => {
				console.log("Found doc in DB = ", data.data()[object[1]]);
				let doc_data = data.data()[object[1]];

				if (typeof doc_data === "string" && object[3]) {
					console.log("Case 1");
					obj[`${object[1]}`] = [object[0], object[3]];
					db.collection("content-translation")
						.doc(searchdocId)
						.update(obj)
						.then((_) => {
							console.log("Updated on DB!");
							window.location.reload();
						});
				} else if (typeof doc_data === "string" && object[3] === false) {
					console.log("Case 2");
					db.collection("content-translation")
						.doc(searchdocId)
						.update(obj)
						.then((_) => {
							console.log("Updated on DB!");
							window.location.reload();
						});
				} else if (typeof doc_data === "object" && object[3]) {
					console.log("Case 3");
					db.collection("content-translation")
						.doc(searchdocId)
						.update({ [object[1]]: fieldValue.arrayUnion(object[3]) })
						.then((_) => {
							console.log("Updated on DB!");
							window.location.reload();
						});
				} else if (
					typeof doc_data === "object" &&
					object[3] === false &&
					doc_data.length === 3
				) {
					console.log("Case 4");
					console.log("3rd");
					db.collection("content-translation")
						.doc(searchdocId)
						.update({ [object[1]]: fieldValue.arrayRemove(true) })
						.then((_) => {
							console.log("Updated on DB!");
							window.location.reload();
						});
				} else if (
					typeof doc_data === "object" &&
					object[3] === false &&
					doc_data.length === 2
				) {
					console.log("Case 5");
					obj[`${object[1]}`] = object[0];
					db.collection("content-translation")
						.doc(searchdocId)
						.update(obj)
						.then((_) => {
							console.log("Updated on DB!");
							window.location.reload();
						});
				} else {
					db.collection("content-translation")
						.doc(searchdocId)
						.update(obj)
						.then((_) => {
							console.log("Updated on DB!");
							window.location.reload();
						});
				}
			});
		}

		// setTimeout(() => {
		// 	window.location.reload();
		// }, 3000);
	}
};
