import {
  GET_WEB_ORDERS,
  // GET_GA_DATA,
  DELETE_ITEM,
  ADD_ITEM,
  // EDIT_ITEM
} from "../actions/types";

const initialState = {
  contentData: [],
  gaData: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_WEB_ORDERS:
      return {
        ...state,
        contentData: action.payload,
      };

    case DELETE_ITEM:
      return {
        ...state,
        contentData: state.contentData.filter(
          (item, index) => index !== action.payload
        ),
      };

    case ADD_ITEM:
      console.log(state)
      return {
        ...state,
        contentData: [...state.contentData, action.payload.data],
      };

    // case EDIT_ITEM:
    //   return {
    //     ...state,
    //     [entryId]: {
    //       ...state[entryId],
    //       [entryName]: [
    //         ...state[entryId][entryName],
    //         uniqueEntry
    //       ]
    //     }
    //   };

    default:
      return state;
  }
}
