import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { firebase } from "../../../firebase";
import "react-confirm-alert/src/react-confirm-alert.css";
import { IconButton } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Tooltip from "@material-ui/core/Tooltip";

import HistoryIcon from "@material-ui/icons/History";
import "./table.css";

// const _ = require("lodash");

const db = firebase.firestore();
const admins = [
  "aayush@playshifu.com",
  "sharmishtha@playshifu.com",
  "sharmistha@playshifu.com",
  "jay@playshifu.com",
  "admin@playshifu.com",
  "ajinkya@playshifu.com",
  "admin@playshifu.com",
];

const kits_mapping = [
  "new_keys", //0
  "generic",
  "count_common",
  "theo_common",
  "theo",
  "numero_common", //5
  "numerov2",
  "space",
  "dana",
  "mcc",
  "mccv2", //10
  "link_common",
  "gears",
  "wordhunt",
  "memory",
  "frog", //15
  "physics_prototype",
  "pipes",
  "piano_common",
  "alphabeto_common",
  "cat", //20
  "fish",
  "dino",
  "puzzle",
  "oko",
  "oko2", //25
  "caveman",
  "crossword",
  "lettersDetective",
  "catapult_common",
  "iggy", //30
  "tizi",
  "waterfall",
  "orboot_keys",
  "earth",
  "mars", //35
  "dino",
  "new_keys_tacto",
  "tacto_generic",
  "classic_common",
  "ludo", //40
  "tnl",
  "checkers",
  "tic_tac_toe",
  "laser_common",
  "sana", //45
  "laser_detective",
  "maze",
  "slime_saga",
  "chess_common",
  "coding_common", //50
];

// const kits_mapping_plugo = {
//   new_keys: 0,
//   generic: 1,
//   catapault_common: 2,
//   iggy: 3,
//   tizi: 4,
//   waterfall: 5,
//   alphabeto_common: 6,
//   cat: 7,
//   fish: 8,
//   dino: 9,
//   puzzle: 10,
//   oko: 11,
//   caveman: 12,
//   crossword: 13,
//   piano_common: 14,
//   link_common: 15,
//   gears: 16,
//   word_hunt: 17,
//   memory: 18,
//   frog: 19,
//   physics_prototype: 20,
//   pipes: 21,
// };
class HistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table_data: [],
      table_data_unfiltered: [],
      editPopupData: [],
      open: false,
      openR: false,
      openD: false,
      editData: "",
      deleteData: [],
      selectedTab: 0,
      selectedTabNew: 0,
      currentUserEmail: "",
      popupData: [[]],
      dataToBeUpdated: [],
      gameTab: "",
      loader: false,
      Key: "",
      row: "",
      game: "",
      selected: [],
      parent: "",
    };
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        TableHead: {
          root: {
            float: "left",
          },
        },
      },
    });

  componentWillMount() {
    // this.props.getWebOrders();

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // console.log("MOUNTED1", user.email);
        this.setState({ currentUserEmail: user.email });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    // let url_array = this.props.history.location.pathname.split("/");
    // url_array = url_array.filter((el) => {
    //   return el !== "";
    // });
    // if (url_array[1] === "Plugo") {
    //   this.setState({ selectedTab: 100 });
    // } else if (url_array[1] === "Tacto") {
    //   this.setState({ selectedTab: 200 });
    // } else if (url_array[1] === "Orboot") {
    //   this.setState({ selectedTab: 300 });
    // }
    // this.setState({ parent: url_array[1] });
    // let second_tab = url_array[url_array.length - 1];
    // if (second_tab === "Numero") {
    //   this.setState({ selectedTab: 7, gameTab: "numerov2" });
    // } else if (second_tab === "Mcc2") {
    //   this.setState({ selectedTab: 11, gameTab: "mccv2" });
    // } else if (second_tab === "ISpy") {
    //   this.setState({ selectedTab: 15, gameTab: "memory" });
    // } else if (second_tab === "Tunes Common") {
    //   this.setState({ selectedTab: 19, gameTab: "piano_common" });
    // } else if (second_tab === "Letters Common") {
    //   this.setState({ selectedTab: 20, gameTab: "alphabeto_common" });
    // } else if (second_tab === "Tacto Generic") {
    //   this.setState({ selectedTab: 39, gameTab: "tacto_generic" });
    // } else if (second_tab === "LocoJojo") {
    //   this.setState({ selectedTab: 17, gameTab: "physics_prototype" });
    // } else if (second_tab === "Letters Detective") {
    //   this.setState({ selectedTab: 29, gameTab: "lettersDetective" });
    // } else {
    //   kits_mapping.map((entry, idx) => {
    //     second_tab = decodeURI(second_tab);
    //     second_tab = second_tab.replace(" ", "_");
    //     if (entry === second_tab.toLowerCase()) {
    //       this.setState({
    //         selectedTab: idx + 1,
    //         gameTab: second_tab.toLowerCase(),
    //       });
    //     }
    //   });
    // }
    // // console.log(this.state.selectedTab, nextProps.contentData);
    // setTimeout(() => {
    //   this.setState({
    //     table_data_unfiltered: nextProps.contentData,
    //     table_data: this.filterData(nextProps.contentData),
    //   });
    // }, 100);
  }

  render() {


    const options = {
      // filter: false,
      print: false,
      //   viewColumns:
      //     this.state.selectedTab === 1 ||
      //     this.state.selectedTab === 38
      //       ? false
      //       : true,
      selectableRows: false,
      //   onRowsSelect: this.onRowsSelect,
      //   download:
      //     admins.includes(this.state.currentUserEmail) ||
      //     (!admins.includes(this.state.currentUserEmail) &&
      //       this.state.selectedTab === 1 || this.state.selectedTab === 38)
      //       ? true
      //       : false,
      //   onDownload: this.onDownload,
      //   downloadOptions: {
      //     filename: "tableDownload.csv",
      //     seperator: ",",
      //     filterOptions: {
      //       useDisplayedColumnsOnly: true,
      //       useDisplayedRowsOnly: true,
      //     },
      //   },
      //   onRowsDelete: this.openPopup,
      //   customToolbar: admins.includes(this.state.currentUserEmail)
      //     ? () => <CustomToolbar handleClick={this.handleClickPopup} />
      //     : null,
    };

    if (this.state.loader === true) {
      return (
        <div>
          <img
            style={{ height: "200px", marginTop: "175px" }}
            src="https://flevix.com/wp-content/uploads/2020/01/Preloader.gif"
          />
          <p style={{ marginTop: "-50px" }}>Please do not refresh</p>
        </div>
      );
    }
    return (
      <div>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <Tooltip title="Exit To App">
              <IconButton style={{ marginLeft: " -10px" }}>
                <ExitToAppIcon
                  fontSize="medium"
                  style={{ color: "white" }}
                  onClick={(event) => (window.location.href = "/app")}
                />
              </IconButton>
            </Tooltip>

            <Typography variant="h6" noWrap style={{ marginLeft: "10px" }}>
              SHIFU Content History
            </Typography>
          </Toolbar>
        </AppBar>
        {/* <Popup
          dataToBeUpdated={this.state.dataToBeUpdated}
          open={this.state.open}
          handleClose={this.onClosePopup}
          selectedTab={this.state.gameTab}
          tabNumber={this.state.selectedTab}
          onCancelOrder={this.onCancelOrder}
        />
        <PopupRakuten
          open={this.state.openR}
          data={this.state.editPopupData}
          handleClose={this.onClosePopup}
          onCancelOrder={this.onCancelOrder}
        /> */}
        {/* <FormControl> */}
        {/* {this.state.selectedTab === 1 ? (
            <InputLabel
              style={{ fontVariant: "all-petite-caps", fontSize: "x-large" }}
            >
              <div>
                <p
                  style={{
                    textAlign: "center",
                    margin: "-25px 0px",
                    fontSize: "33px",
                    color: "black",
                    border: "1px solid",
                  }}
                >
                  NewKeys
                </p>
              </div>
            </InputLabel>
          ) : (
            <InputLabel
              style={{ fontVariant: "all-petite-caps", fontSize: "x-large" }}
            >
              <div>
                <p
                  style={{
                    textAlign: "center",
                    margin: "-25px 0px",
                    fontSize: "33px",
                    color: "black",
                    border: "1px solid",
                  }}
                >
                  {" "}
                  {kits_mapping[this.state.selectedTab - 1]}{" "}
                </p>
              </div>
            </InputLabel>
          )} */}
        {/* </FormControl>
        {this.state.selectedTab === 1 ||
        this.state.selectedTab === 38 ||
        !admins.includes(this.state.currentUserEmail) ? null : (
          <div style={{ float: "right" }}>
            <ReactFileReader
              handleFiles={(e) => this.handleFiles(e)}
              fileTypes={".csv"}
            >
              <Button
                type="submit"
                // style={{ marginLeft: "90%", marginBottom: "0.5%" }}
                variant="contained"
                color="primary"
                className="btn"
              >
                Upload CSV
              </Button>
            </ReactFileReader>
          </div>
        )}
        <Dialog open={this.state.openD} aria-labelledby="form-dialog-title">
          <DialogTitle style={{ marginTop: "2%" }} id="form-dialog-title">
            Delete Row{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClosePopup} color="primary">
              Close
            </Button>
            <Button onClick={(e) => this.onRowsDelete(e)} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog> */}
        <div className="DataTable" style={{ marginTop: "50px" }}>
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable
              //   data={this.state.table_data}
              options={options}
            />
          </MuiThemeProvider>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({
//   contentData: state.webOrders.contentData,
// });

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getWebOrders: () => {
//       dispatch(getWebOrders);
//     },
//     deleteUserDetails: (data) => {
//       deleteUserDetails(dispatch, data);
//     },
//   };
// };

export default connect()(withRouter(HistoryTable));
