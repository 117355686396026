import { DialogTitle } from "@material-ui/core";
import { DialogContentText } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";

function EditWebContentPopup(props) {
	const [popupState, setpopupState] = useState(props.open);
	const [firstLoad, setfirstLoad] = useState(true);
	const [inputValue, setinputValue] = useState("");

	useEffect(() => {
		if (!firstLoad) {
			setpopupState(true);
		} else {
			setfirstLoad(false);
		}
	}, [props.open]);

	useEffect(() => {
		setinputValue(
			props.data.rowData ? props.data.rowData[props.data.columnIndex] : ""
		);
	}, [props.data.rowData]);

	const onUpdateDetails = () => {
		console.log("new value---", inputValue);
		console.log("language---", props.data.columnData.name);
		console.log("key---", props.data.rowData[0]);

		var reqBody = {
			key: props.data.rowData[0],
			language: props.data.columnData.name,
			value: inputValue,
		};

		reqBody = JSON.stringify(reqBody);

		var requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: reqBody,
		};

		fetch(
			`https://wwwserver.playshifu.com/updatewebsitecontent`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				console.log("update api response ---", result);
				setpopupState(false);
				props.handleUpdate(
					props.data.rowIndex,
					props.data.columnIndex,
					inputValue
				);
			})
			.catch((error) => {
				console.log("error", error);
				setpopupState(false);
			});
	};

	return (
		<div>
			<Dialog
				// open={this.state.open}
				// onClose={props.handleClose}
				open={popupState}
				aria-labelledby="form-dialog-title"
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle style={{ marginTop: "2%" }} id="form-dialog-title">
					Update Translation (
					{props.data.columnData ? props.data.columnData.label : ""})
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{props.data.rowData ? props.data.rowData[0] : ""}
						{/* test */}
					</DialogContentText>
					{/* {props.data[1] === "Key" ? (
						<div>
							<Alert severity="error" variant="outlined">
								{" "}
								The key is duplicate
							</Alert>
							<TextField
								autoFocus
								onChange={
									(e) => {}
									// this.onChangeQuantity(e)
								}
								margin="dense"
								id="outlined-multiline-static"
								label="Text"
								type="name"
								// value={this.state.translation}
								fullWidth
								multiline
								variant="outlined"
								rows={5}
							/>
						</div>
					) : ( */}
					<TextField
						autoFocus
						onChange={(e) => setinputValue(e.target.value)}
						margin="dense"
						id="outlined-multiline-static"
						label="Text"
						type="name"
						value={inputValue}
						fullWidth
						multiline
						variant="outlined"
						rows={5}
					/>
					{/* )} */}
				</DialogContent>
				<DialogActions>
					<Button
						// onClick={props.handleClose}
						onClick={() => setpopupState(false)}
						color="primary"
					>
						Close
					</Button>
					<Button onClick={(e) => onUpdateDetails()} color="primary">
						Update
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default EditWebContentPopup;
