import { firebase } from "../firebase";
const db = firebase.firestore();

export const deleteDoc = (id) => {
	return new Promise((resolve, reject) => {
		db.collection("content-translation")
			.doc(id)
			.delete()
			.then((res, err) => console.log(res))
			.then((res) => {
				console.log(res);
				resolve();
			});
	});
};

export const insertDoc = (id, data) => {
	return new Promise((resolve, reject) => {
		db.collection("content-translation")
			.doc(id)
			.set(data)
			.then((res, err) => console.log(res))
			.then((res) => {
				console.log("Inserted docId = ", id);
				resolve();
			});
	});
};

export const updateDoc = (id, data) => {
	return new Promise((resolve, reject) => {
		db.collection("content-translation")
			.doc(id)
			.update(data)
			.then((res, err) => console.log(res))
			.then((res) => {
				resolve();
			});
	});
};
