import { IconButton } from "material-ui";
import EditIcon from "@material-ui/icons/Edit";
import { createMuiTheme, MuiThemeProvider } from "material-ui/styles";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { firebase } from "../../../firebase";
import EditWebContentPopup from "../../../Components/EditWebContentPopup";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
const adminEmails = require("../../../static_json/admins.json");

// const getMuiTheme = () =>
// 	createMuiTheme({
// 		overrides: {
// 			TableHead: {
// 				root: {
// 					float: "left",
// 				},
// 			},
// 		},
// 	});
function DatatableWeb(props) {
	const [currentUserMail, setcurrentUserMail] = useState("");
	const [editPopupOpen, seteditPopupOpen] = useState(false);
	const [editData, seteditData] = useState([]);
	const [device, setdevice] = useState("");
	const [page, setPage] = useState("");

	useEffect(() => {
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				console.log("MOUNTED1", user.email);
				setcurrentUserMail(user.email);
			}
		});
	}, []);

	const options = {
		// filterType: "checkbox",
		// filter: false,
		print: false,
		selectableRows: false,
		// selectableRows: this.state.selectedTab === 1 ? false : true,
		// onRowsSelect: this.onRowsSelect,
		// onDownload: this.onDownload,
		downloadOptions: {
			filename: "tableDownload.csv",
			seperator: ",",
			filterOptions: {
				useDisplayedColumnsOnly: true,
				useDisplayedRowsOnly: true,
			},
		},
		// onRowsDelete: this.openPopup,
		// customToolbar: () => (
		//     <CustomToolbar handleClick={this.handleClickPopup} />
		// ),
	};

	const columns = [
		{
			name: "Key",
			label: "Key",
		},
		{
			name: "english",
			label: "English",
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<p>{tableMeta.rowData[1]}</p>
							{adminEmails.includes(currentUserMail) ? (
								<IconButton
									onClick={
										(e) => {
											console.log(
												value,
												tableMeta,
												updateValue
											);
											seteditPopupOpen(!editPopupOpen);
											seteditData(tableMeta);
										}

										// this.editButtonClicked(
										//     e,
										//     value,
										//     tableMeta
										// )
									}
									style={{
										width: "5px",
										height: "5px",
										margin: "10px 0 0 5px",
										padding: "0px !important",
									}}
									variant="contained"
									color="primary"
								>
									{/* {this.state.selectedTab === 1 ? null : ( */}
									<EditIcon />
									{/* )} */}
								</IconButton>
							) : (
								<div />
							)}
						</div>
					);
				},
			},
		},
		{
			name: "russian",
			label: "Russian",
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<p>{tableMeta.rowData[2]}</p>
							{adminEmails.includes(currentUserMail) ? (
								<IconButton
									onClick={(e) => {
										console.log(
											value,
											tableMeta,
											updateValue
										);
										seteditPopupOpen(!editPopupOpen);
										seteditData(tableMeta);
									}}
									style={{
										width: "5px",
										height: "5px",
										margin: "10px 0 0 5px",
										padding: "0px !important",
									}}
									variant="contained"
									color="primary"
								>
									<EditIcon />
								</IconButton>
							) : (
								<div />
							)}
						</div>
					);
				},
			},
		},
	];

	const [tableData, settableData] = useState([]);

	// useEffect(() => {
	// 	// console.log("fetching content from rds");
	// 	var tempData = [];
	// 	fetch(
	// 		"http://localhost:5000/getwebsitecontent?device=desktop&page=home",
	// 		[]
	// 	)
	// 		.then((response) => response.json())
	// 		.then((result) => {
	// 			for (var key in result) {
	// 				tempData.push([
	// 					key,
	// 					result[key]["english"],
	// 					result[key]["russian"],
	// 				]);
	// 			}
	// 			// console.log(result);
	// 			settableData(tempData);
	// 		})
	// 		.catch((error) => console.log("api error", error));
	// }, []);

	const onUpdate = (row, col, val) => {
		// console.log("update table data", row, col, val);
		var tempData = [...tableData];
		tempData[row][col] = val;
		settableData(tempData);
	};

	let url_array = props.history.location.pathname.split("/");
	url_array = url_array.filter((el) => {
		return el != "";
	});
	console.log("url_array ---", url_array[0], url_array[1], url_array[2]);
	if (url_array[2] && (device != url_array[1] || page != url_array[2])) {
		setdevice(url_array[1]);
		setPage(url_array[2]);
		var tempData = [];
		settableData([]);
		fetch(
			`https://wwwserver.playshifu.com/getwebsitecontent?device=${url_array[1]}&page=${url_array[2]}`,
			[]
		)
			.then((response) => response.json())
			.then((result) => {
				for (var key in result) {
					tempData.push([
						key,
						result[key]["english"],
						result[key]["russian"],
					]);
				}
				// console.log(result);
				settableData(tempData);
			})
			.catch((error) => console.log("api error", error));
	}

	return (
		<div>
			<EditWebContentPopup
				open={editPopupOpen}
				data={editData}
				// handleClose={this.onClosePopup}
				handleUpdate={onUpdate}
				// onCancelOrder={this.onCancelOrder}
			/>
			<div style={{ marginTop: "40px" }}>
				<MuiThemeProvider>
					<MUIDataTable
						data={tableData}
						columns={columns}
						options={options}
					/>
				</MuiThemeProvider>
			</div>
		</div>
	);
}

export default connect(null, null)(withRouter(DatatableWeb));
